import '../styles/user.sass'
import AddPoint from './AddPoint'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { business, channels, discount, payment, delivery } from '../context/Variables'

const Client = () => {
    const form = useRef(null)
    const navigate = useNavigate()
    const { action, id } = useParams()
    const [ users, setUsers ] = useState([])
    const [ points, setPoints ] = useState([])
    const [ products, setProducts ] = useState([])
    const [ scrollTop, setScrollTop ] = useState(0)
    const [ post, setPost ] = useState({login: '0', discount: []})
    const { main, user, updateData, apiURL } = useContext(AppContext)

    const redirect = data => {
        setPoints([])
        toast.success(data.updated, { theme: "colored", transition: Flip })
        navigate(`/clientes/editar/${data.id}/`)
    }
    const updateField = (e) => {
        let _value = e.target.value
        if(e.target.type == 'file'){
            if(e.target.files[0].size > 5242880)
                toast.error('El archivo debe pesar menos de 5 MB.', { theme: "colored", transition: Flip })
            else
                setPost({...post, [e.target.name]: e.target.files[0]})
        }else{
            if(e.target.name == 'discount'){
                _value = post[e.target.name]
                _value[e.target.value] = !['true', true].includes(_value[e.target.value])
            }
            if(e.target.name == 'login' && _value == 0)
                setPost({...post, [e.target.name]: _value, products: []})
            else if(e.target.name == 'products'){
                let _products = post.products ?  [...post.products] : []
                if(e.target.checked)
                    _products.push(_value)
                else
                    _products = _products.filter(p => p != _value)
                setPost({...post, products: _products})
            }else if(e.target.name == 'buyed_products'){
                let _buyed_products = post.buyed_products ?  [...post.buyed_products] : []
                if(e.target.checked)
                    _buyed_products.push(_value)
                else
                    _buyed_products = _buyed_products.filter(p => p != _value)
                setPost({...post, buyed_products: _buyed_products})
            }else
                setPost({...post, [e.target.name]: _value})
        }
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('client', 'update', formData, redirect)
    }
    const doActivate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('activate', id)
        formData.append('token', localStorage.getItem('userToken'))
        updateData('client', 'activate', formData, redirect)
    }
    const addPoint = () => {
        setScrollTop(main.current.scrollTop)
        setPoints([...points, {n: points.at(-1).n + 1, client: id}])
    }
    const updatePoint = (data) => {
        setScrollTop(main.current.scrollTop)
        setPoints(() => {
            const _index = points.findIndex(p => p.n == data?.n)
            const _points = [...points]
            _points[_index] = data
            return _points
        })
    }
    const removePoint = (key, id) => {
        setScrollTop(main.current.scrollTop)
        if(id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            updateData('point', 'delete', formData, (data) => {
                setPoints(points.filter(p => p.n != key))
                toast.warning(data.updated, { theme: "colored", transition: Flip })
            })
        }else
            setPoints(points.filter(p => p.n != key))
    }

    useEffect(() => {
        if(products.length == 0){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}products/all/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setProducts(data.data)
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
        }
        if(users.length == 0){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}users/all/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    console.log(data)
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setUsers(data.data)
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
        }
        if(points.length == 0 && action == 'editar'){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}client/get/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined'){
                        toast.error(data.error, { theme: "colored", transition: Flip })
                        navigate('/clientes/')
                    }else{
                        data.buyed_products.split(',').forEach((d, i) => data['buyed_products'+i] = d)
                        data.products = data.products.split(',')
                        setPost(data)
                        setPoints(data.points.length > 0 ? data.points : [{n: 1, client: id}])
                    }
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }else if(points.length == 0 && id)
            setPoints([{n: 1, client: id}])
        if(main.current.scrollTop == 0)
            main.current.scrollTop = scrollTop
    }, [scrollTop, id, points])

    return <section className="client">
        <div className="content">
            <Link className="go-back" to="/clientes/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>{action == 'editar' ? 'Editar cliente' : 'Creación de cliente'}</h1>
            <p>Diligencie el formulario con los datos {action == 'editar' ? 'del cliente.' : 'de los clientes que desea crear.'}</p>
            <h5>Información del cliente</h5>
            {user?.type < 2 ? <form action={`${apiURL}export/client/`} className="download" method="POST" target="_blank">
                <input type="hidden" name="id" value={id} />
                <input type="hidden" name="token" value={localStorage.getItem('userToken')} />
                <button className="button" >Descargar</button>
            </form> : null}
            <form className="parent-block" onSubmit={doUpdate}>
                <div className="field">
                    <label htmlFor="nit">NIT / CC</label>
                    <input type="number" name="nit" value={post?.nit || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="name">Razón social</label>
                    <input type="text" name="name" value={post?.name || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="department">Departamento</label>
                    <input type="text" name="department" value={post?.department || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="city">Ciudad</label>
                    <input type="text" name="city" value={post?.city || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="email">Correo electrónico</label>
                    <input type="email" name="email" value={post?.email || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="phone">Teléfono</label>
                    <input type="text" name="phone" value={post?.phone || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="cellphone">Celular</label>
                    <input type="number" name="cellphone" value={post?.cellphone || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="business">Unidad de negocio</label>
                    <select name="business" value={post?.business || ''} onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                        <option value="">Seleccionar</option>
                        {business.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="channel">Canal y subcanal</label>
                    <select name="channel" value={post?.channel || ''} onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                        <option value="">Seleccionar</option>
                        {channels.map((c, k) => {
                            let _r = ''
                            if(c.subchannels)
                                return <optgroup key={k} label={c.channel}>{c.subchannels.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}</optgroup>
                            else
                                return <option key={k} value={c.id}>{c.name}</option>
                        })}
                    </select>
                </div>
                <div className="field">
                    <label className="w-100" htmlFor="discount">Descuento (%)</label>
                    {discount.map((c, k) => <label key={k}><input type="checkbox" name="discount" value={c.id} checked={['true', true].includes(post?.discount[c.id])} onChange={updateField} readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} /> {c.name}</label>)}
                </div>
                <div className="field">
                    <label htmlFor="payment">Medio de pago</label>
                    <select name="payment" value={post?.payment || ''} onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                        <option value="">Seleccionar</option>
                        {payment.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="delivery">Tipo de entrega</label>
                    <select name="delivery" value={post?.delivery || ''} onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                        <option value="">Seleccionar</option>
                        {delivery.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="deadline">Plazo (días)</label>
                    <input type="number" name="deadline" value={post?.deadline || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="quota">Cupo solicitado (valor)</label>
                    <input type="number" name="quota" value={post?.quota || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="units">Unidades compra mensual</label>
                    <input type="number" name="units" value={post?.units || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="buyed_products0">Productos que compra el cliente</label>
                    <select name="buyed_products0" value={post?.buyed_products0 || ''} onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                        <option value="">Seleccionar</option>
                        {products.map((c, k) => <option key={k} value={c.id}>{`(${c.code}) ${c.name}`}</option>)}
                    </select>
                </div>
                <div className="field">
                    <label>&nbsp;</label>
                    <select name="buyed_products1" value={post?.buyed_products1 || ''} onChange={updateField} readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                        <option value="">Seleccionar</option>
                        {products.map((c, k) => <option key={k} value={c.id}>{`(${c.code}) ${c.name}`}</option>)}
                    </select>
                </div>
                <div className="field">
                    <label>&nbsp;</label>
                    <select name="buyed_products2" value={post?.buyed_products2 || ''} onChange={updateField} readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                        <option value="">Seleccionar</option>
                        {products.map((c, k) => <option key={k} value={c.id}>{`(${c.code}) ${c.name}`}</option>)}
                    </select>
                </div>

                <h5 className="w-100">Información del contacto</h5>
                <div className="field">
                    <label htmlFor="contact">Comprador</label>
                    <input type="text" name="contact" value={post?.contact || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="contact_email">Correo electrónico</label>
                    <input type="email" name="contact_email" value={post?.contact_email || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="contact_phone">Teléfono</label>
                    <input type="number" name="contact_phone" value={post?.contact_phone || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>
                <div className="field">
                    <label htmlFor="contact_cellphone">Celular</label>
                    <input type="number" name="contact_cellphone" value={post?.contact_cellphone || ''} autoComplete="off" onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} />
                </div>

                {user?.type < 2 || (!id || post?.visibility == 2) ? <>
                    <h5 className="w-100">Gestión de pedidos</h5>
                    <div className="field">
                        <label htmlFor="login">Puede gestionar</label>
                        <select name="login" value={post?.login} onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                            <option value="0">No</option>
                            <option value="1">Si</option>
                        </select>
                    </div>
                    <div className="field left">
                        <label htmlFor="user">Comercial asignado</label>
                        <select name="user" value={post?.user} onChange={updateField} required readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2}>
                            <option value="">Seleccionar</option>
                            {users.map((u, k) => <option key={k} value={u.id}>{u.full_name}</option>)}
                        </select>
                    </div>
                    <div className={`field ${post?.login == '1' ? 'products' : 'hide'}`}>
                        <label htmlFor="products">Productos que puede autogestionar</label>
                        {products?.map((p, i) => <div key={i} className="checkitem"><input type="checkbox" name="products" value={p.id} checked={!!post?.products?.find(pid => pid == p.id)} onChange={updateField} readOnly={id && user?.type == 2 && post?.visibility != 2} disabled={id && user?.type == 2 && post?.visibility != 2} /> {p.name}</div>)}
                    </div>

                    <h5 className="w-100">Documentos</h5>
                    <div className="field">
                        <label htmlFor="puntos_venta">Listado de puntos de venta (xls, xlsx)</label>
                        {post?.puntos_venta && <a download={post?.puntos_venta} rel="noreferrer" href={`${apiURL}../files/${post?.puntos_venta}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span></a>}
                        <input type="file" name="puntos_venta" autoComplete="off" onChange={updateField} accept=".xls, .xlsx" />
                    </div>
                    <div className="field">
                        <label htmlFor="rut">RUT (pdf)</label>
                        {post?.rut && <a download={post?.rut} rel="noreferrer" href={`${apiURL}../files/${post?.rut}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span></a>}
                        <input type="file" name="rut" autoComplete="off" onChange={updateField} accept=".pdf" />
                    </div>
                    <div className="field">
                        <label htmlFor="camara_comercio">Cámara de comercio (pdf)</label>
                        {post?.camara_comercio && <a download={post?.camara_comercio} rel="noreferrer" href={`${apiURL}../files/${post?.camara_comercio}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span></a>}
                        <input type="file" name="camara_comercio" autoComplete="off" onChange={updateField} accept=".pdf" />
                    </div>
                    <div className="field">
                        <label htmlFor="representante_legal">Cédula representante legal (pdf, jpg, png)</label>
                        {post?.representante_legal && <a download={post?.representante_legal} rel="noreferrer" href={`${apiURL}../files/${post?.representante_legal}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span></a>}
                        <input type="file" name="representante_legal" autoComplete="off" onChange={updateField} accept=".pdf, .jpg, .jpeg, .png" />
                    </div>
                    <div className="field">
                        <label htmlFor="covinoc">Formato COVINOC (pdf, jpg, png)</label>
                        {post?.covinoc && <a download={post?.covinoc} rel="noreferrer" href={`${apiURL}../files/${post?.covinoc}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span></a>}
                        <input type="file" name="covinoc" autoComplete="off" onChange={updateField} accept=".pdf, .jpg, .jpeg, .png" />
                    </div>
                    <div className="field">
                        <label htmlFor="estados_financieros">Estados financieros (pdf)</label>
                        {post?.estados_financieros && <a download={post?.estados_financieros} rel="noreferrer" href={`${apiURL}../files/${post?.estados_financieros}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span></a>}
                        <input type="file" name="estados_financieros" autoComplete="off" onChange={updateField} accept=".pdf" />
                    </div>
                    <div className="field">
                        <label className="w-100"></label>
                        {id && (user?.type < 2 && post?.visibility == 2) && <a className="button green" onClick={doActivate}>Activar</a>}
                        <button className={'button'+(id && user?.type < 2 && post?.visibility == 2 && ' left' || '')} type="submit">Guardar</button>
                    </div>
                </> : <>
                    <h5 className="w-100">Documentos</h5>
                    {post?.puntos_venta && <div className="field">
                        <label className="w-100" htmlFor="puntos_venta">Listado de puntos de venta (xls, xlsx)</label>
                        <a download={post?.puntos_venta} rel="noreferrer" href={`${apiURL}../files/${post?.puntos_venta}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span> Descargar</a>
                    </div>}
                    {post?.rut && <div className="field">
                        <label className="w-100" htmlFor="rut">RUT (pdf)</label>
                        <a download={post?.rut} rel="noreferrer" href={`${apiURL}../files/${post?.rut}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span> Descargar</a>
                    </div>}
                    {post?.camara_comercio && <div className="field">
                        <label className="w-100" htmlFor="camara_comercio">Cámara de comercio (pdf)</label>
                        <a download={post?.camara_comercio} rel="noreferrer" href={`${apiURL}../files/${post?.camara_comercio}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span> Descargar</a>
                    </div>}
                    {post?.representante_legal && <div className="field">
                        <label className="w-100" htmlFor="representante_legal">Cédula representante legal (pdf, jpg, png)</label>
                        <a download={post?.representante_legal} rel="noreferrer" href={`${apiURL}../files/${post?.representante_legal}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span> Descargar</a>
                    </div>}
                    {post?.covinoc && <div className="field">
                        <label className="w-100" htmlFor="covinoc">Formato COVINOC (pdf, jpg, png)</label>
                        <a download={post?.covinoc} rel="noreferrer" href={`${apiURL}../files/${post?.covinoc}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span> Descargar</a>
                    </div>}
                    {post?.estados_financieros && <div className="field">
                        <label className="w-100" htmlFor="estados_financieros">Estados financieros (pdf)</label>
                        <a download={post?.estados_financieros} rel="noreferrer" href={`${apiURL}../files/${post?.estados_financieros}`} target="_blank" className="button min" style={{padding: '0 5px', height: '20px'}}><span className="material-icons">file_download</span> Descargar</a>
                    </div>}
                </>}
            </form>
            {id && user?.type < 2 && <>
                <h5>Información de sucursales</h5>
                {points.length > 0 && points?.map((p, i) => <AddPoint key={p.n} n={i+1} add={addPoint} update={updatePoint} remove={removePoint} point={p} />)}
            </> || null}
        </div>
    </section>
}
export default Client