import '../styles/user.sass'
import { toast, Flip } from 'react-toastify'
import Pagination from '../components/Pagination'
import { AppContext } from '../context/AppContext'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect, useContext, useMemo, useCallback } from 'react'

const Admin = () => {
    const { page = 1 } = useParams()
    const [ pages, setPages ] = useState(1)
    const [ users, setUsers ] = useState(null)
    const [ post, setPost ] = useState({s: '', sort: 'id', type: ''})
    const { load, updateData, showTooltip, hideTooltip, apiURL } = useContext(AppContext)
    const userTypes = useMemo(() => ['Tecnología', 'Administrador', 'Comercial'], [])

    const updateField = useCallback((e) => {
        let _post = {...post}
        if(e.target.type == 'checkbox' && !e.target.checked)
            _post[e.target.name] = ''
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }, [post])
    const update = useCallback((id, action) => {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('token', localStorage.getItem('userToken'))
        updateData('user', action, formData)
        setUsers(null)
    }, [post])
    
    useEffect(() => {
        if(localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            for(let key in post)
                formData.append(key, post[key])
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}users/${page > 1 ? `page/${page}/` : ''}`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        setUsers(data.data)
                        setPages(data.pages)
                    }
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }
        return () => hideTooltip()
    }, [page, post])

    return <section className="admin">
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Listado de usuarios</h1>
            <p>Consulte aquí los usuarios administradores y comerciales.</p>
            <div className="mobile">
                <Link className="button" to="/usuarios/crear/">Crear un nuevo usuario</Link>
            </div>
            <div className="filter">
                <div className="field search">
                    <span className="material-icons">search</span>
                    <input type="text" name="s" placeholder="Búsqueda rápida..." autoComplete="off" value={post?.s || ''} onChange={updateField} />
                </div>
                <div className="in-line">
                    <div className="field type">
                        <label htmlFor="type">Rol:</label>
                        <select name="type" value={post?.type || ''} onChange={updateField}>
                            <option value="">Todos</option>
                            <option value="1">Administrador</option>
                            <option value="2">Comercial</option>
                        </select>
                        <span className="material-icons">expand_more</span>
                    </div>
                    <div className="field sort">
                        <label htmlFor="sort">Ordenar por:</label>
                        <select name="sort" value={post?.sort || ''} onChange={updateField}>
                            <option value="id">ID</option>
                            <option value="full_name">Nombre</option>
                            <option value="email">Correo electrónico</option>
                            <option value="type">Rol</option>
                        </select>
                        <span className="material-icons">filter_alt</span>
                    </div>
                    <label htmlFor="inverse" className={`field sort-inverse ${post?.inverse}`}>
                        <span className="material-icons">arrow_drop_down</span>
                        <input type="checkbox" name="inverse" value="desc" checked={post?.inverse || ''} onChange={updateField} />
                    </label>
                </div>
            </div>
            {(users?.length > 0 && <table className="list">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Nombre</th>
                        <th>Correo electrónico</th>
                        <th>Rol</th>
                        <th className="right"><Link className="button green" to="/usuarios/crear/" onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Crear usuario"><span className="material-icons">add</span></Link></th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((u) => <tr key={u.id}>
                        <td>{u.id}</td>
                        <td>{u.image && <img src={u.image} alt={u.full_name} /> || <span className="default material-icons">account_circle</span>}{u.full_name}</td>
                        <td>{u.email}</td>
                        <td>{userTypes[u.type]}</td>
                        <td className="right">
                            <Link className="button" to={`/usuarios/editar/${u.id}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Ver usuario"><span className="material-icons">edit</span></Link>
                            <a className="button" onClick={() => update(u.id, 'delete')} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Eliminar usuario"><span className="material-icons">close</span></a>
                        </td>
                    </tr>)}
                </tbody>
            </table>) || <table className="list">
                <thead>
                    <tr>
                        <th><center>{users == null && 'Cargando...' || 'No se encontró ningún usuario.'}</center></th>
                    </tr>
                </thead>
            </table>}
            <Pagination pages={pages} url="usuarios" />
        </div>
    </section>
}
export default Admin