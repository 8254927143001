import '../styles/product.sass'
import { toast, Flip } from 'react-toastify'
import Pagination from '../components/Pagination'
import { AppContext } from '../context/AppContext'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext, useMemo, useCallback } from 'react'

const Products = () => {
    const { page = 1 } = useParams()
    const [ pages, setPages ] = useState(1)
    const [ products, setProducts ] = useState(null)
    const [ post, setPost ] = useState({s: '', sort: 'id', stock: ''})
    const productStock = useMemo(() => ['No disponible', 'Disponible'], [])
    const { load, updateData, showTooltip, hideTooltip, apiURL } = useContext(AppContext)

    const updateField = useCallback((e) => {
        let _post = {...post}
        if(e.target.type == 'checkbox' && !e.target.checked)
            _post[e.target.name] = ''
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }, [post])
    const update = useCallback((id, action) => {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('token', localStorage.getItem('userToken'))
        updateData('product', action, formData)
        setProducts(null)
    }, [post])
    
    useEffect(() => {
        if(localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            for(let key in post)
                formData.append(key, post[key])
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}products/${page > 1 ? `page/${page}/` : ''}`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        setProducts(data.data)
                        setPages(data.pages)
                    }
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }
        return () => hideTooltip()
    }, [page, post])

    return <section className="products">
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Listado de productos</h1>
            <p>Consulte aquí los productos creados.</p>
            <div className="filter">
                <div className="field search">
                    <span className="material-icons">search</span>
                    <input type="text" name="s" placeholder="Búsqueda rápida..." autoComplete="off" value={post?.s || ''} onChange={updateField} />
                </div>
                <div className="in-line">
                    <div className="field stock">
                        <label htmlFor="stock">Estado:</label>
                        <select name="stock" value={post?.stock || ''} onChange={updateField}>
                            <option value="">Todos</option>
                            <option value="1">Disponible</option>
                            <option value="0">No disponible</option>
                        </select>
                    </div>
                    <div className="field sort">
                        <label htmlFor="sort">Ordenar por:</label>
                        <select name="sort" value={post?.sort || ''} onChange={updateField}>
                            <option value="p.code">Código</option>
                            <option value="b.name">Unidad de negocio</option>
                            <option value="p.name">Nombre</option>
                            <option value="p.stock">Disponibilidad</option>
                        </select>
                        <span className="material-icons">filter_alt</span>
                    </div>
                    <label htmlFor="inverse" className={`field sort-inverse ${post?.inverse}`}>
                        <span className="material-icons">arrow_drop_down</span>
                        <input type="checkbox" name="inverse" value="desc" checked={post?.inverse || ''} onChange={updateField} />
                    </label>
                </div>
            </div>
            {(products?.length > 0 && <table className="list">
                <thead>
                    <tr>
                        <th>Código</th>
                        <th>Unidad de negocio</th>
                        <th>Nombre</th>
                        <th>Disponibilidad</th>
                        <th className="right">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {products.map((p) => <tr key={p.id}>
                        <td className="monospace">{p.code.padStart(7, 0)}</td>
                        <td><img className="business" src={p.business} /></td>
                        <td>{p.name}</td>
                        <td>{productStock[p.stock]}</td>
                        <td className="right">
                            <Link className="button" to={`/productos/editar/${p.id}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Ver producto"><span className="material-icons">edit</span></Link>
                            <a className="button" onClick={() => update(p.id, 'delete')} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Eliminar producto"><span className="material-icons">close</span></a>
                        </td>
                    </tr>)}
                </tbody>
            </table>) || <table className="list">
                <thead>
                    <tr>
                        <th><center>No hay usuarios que cumplan con los filtros de búsqueda.</center></th>
                    </tr>
                </thead>
            </table>}
            <Pagination pages={pages} url="productos" />
            <center>
                <p>&nbsp;</p>
                <Link className="button" to="/productos/crear/">Crear un nuevo producto</Link>
            </center>
        </div>
    </section>
}
export default Products