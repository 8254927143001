import { toast, Flip } from 'react-toastify'

export const getImageData = (file, field, post, setPost) => {
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onerror = error => reject(error);
        reader.onload = () => resolve(reader.result);
    })
        .then(data => {
            let _post = {...post}
            _post[field] = data
            setPost(_post)
        })
        .catch((e) => { toast.error('Ocurrió un error al intentar subir el archivo.', { theme: "colored", transition: Flip }) })
}