import '../styles/user.sass'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { getImageData } from '../hook/GetImageData'
import { useState, useEffect, useContext, useRef } from 'react'

const Profile = () => {
    const imageButton = useRef(null)
    const { user, updateData } = useContext(AppContext)
    const [ post, setPost ] = useState({full_name: false})

    const updateProfile = (data) => {
        let formData = new FormData()
        formData.append('token', localStorage.getItem('userToken'))
        updateData('user', 'login', formData)
        toast.success(data.updated, { theme: "colored", transition: Flip })
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData(post?.typelogin, 'update', formData, updateProfile)
    }
    const updateField = (e) => {
        if(e.target.name === 'image_file')
            getImageData(e.target.files[0], 'image', post, setPost)
        else
            setPost({...post, [e.target.name]: e.target.value})
    }

    useEffect(() => {
        if(user !== null)
            setPost({...user})
    }, [user])

    return <section className="user">
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Editar perfil</h1>
            <p>Actualice la informacion de su perfil.</p>
            <h5>Información del usuario</h5>
            <div className="add-block">
                <h6>Usuario {post?.full_name}</h6>
                <form onSubmit={doUpdate}>
                    {post?.typelogin === 'user' && <div className="field">
                        <label htmlFor="type">Tipo de usuario</label>
                        <select name="type" value={post?.type || ''} required readOnly disabled>
                            <option value="">Seleccionar</option>
                            <option value="1">Administrador</option>
                            <option value="2">Comercial</option>
                        </select>
                    </div> || ''}
                    <div className="field">
                        <label htmlFor="full_name">{post?.typelogin === 'user' && 'Nombre completo' || 'Razón social'}</label>
                        <input type="text" name="full_name" value={post?.full_name || ''} autoComplete="off" onChange={updateField} required readOnly={post?.typelogin === 'client'} disabled={post?.typelogin === 'client'} />
                    </div>
                    <div className="field">
                        <label htmlFor="email">Correo electrónico</label>
                        <input type="email" name="email" value={post?.email || ''} autoComplete="off" onChange={updateField} required readOnly={post?.typelogin === 'client'} disabled={post?.typelogin === 'client'} />
                    </div>
                    <div className="field">
                        <label htmlFor="password">Contraseña</label>
                        <input type="password" name="password" value={post?.password || ''} placeholder="Conservar contraseña actual" autoComplete="off" onChange={updateField} />
                    </div>
                    {post?.typelogin === 'user' && <div className="field image">
                        <label className="w-100" htmlFor="image_file">Foto de perfil</label>
                        <input type="hidden" name="image" value={post?.image || ''} required />
                        <input ref={imageButton} className="hide" type="file" name="image_file" accept=".png, .jpg, .jpeg" onChange={updateField} />
                        <button className="button gray" onClick={(e) => { e.preventDefault(); imageButton.current.click(); }}>{post?.image && 'Cargada' || 'Seleccionar'}</button>
                        <img className={(post?.image && 'profile') || 'hide'} src={post?.image || ''} alt={`Foto de perfil ${post?.full_name}`} />
                    </div> || ''}
                    <div className="field">
                        <label className="w-100">&nbsp;</label>
                        <button className="button" type="submit">Guardar</button>
                    </div>
                </form>
            </div>
        </div>
    </section>
}
export default Profile