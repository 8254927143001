import '../styles/user.sass'
import { toast, Flip } from 'react-toastify'
import { channels } from '../context/Variables'
import Pagination from '../components/Pagination'
import { AppContext } from '../context/AppContext'
import { Link, useParams } from 'react-router-dom'
import { useState, useEffect, useContext, useCallback } from 'react'

const Client = () => {
    const { page = 1 } = useParams()
    const [ pages, setPages ] = useState(1)
    const [ clients, setClients ] = useState(null)
    const [ post, setPost ] = useState({s: '', sort: 'nit', channel: ''})
    const { user, load, updateData, showTooltip, hideTooltip, apiURL } = useContext(AppContext)

    const checkChannel = v => {
        const _value = +v
        let _return = false
        channels.find(c => {
            if(c.id && c.id === _value && !_return) _return = c
            else if(!c.id && !_return)
                _return = c.subchannels.find(s => s.id === _value)
        })
        return _return
    }
    const updateField = useCallback((e) => {
        let _post = {...post}
        if(e.target.type == 'checkbox' && !e.target.checked)
            _post[e.target.name] = ''
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }, [post])
    const update = useCallback((id, action) => {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('token', localStorage.getItem('userToken'))
        updateData('client', action, formData)
        setClients(null)
    }, [post])
    
    useEffect(() => {
        if(localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            for(let key in post)
                formData.append(key, post[key])
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}clients/${page > 1 ? `page/${page}/` : ''}`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        setClients(data.clients)
                        setPages(data.pages)
                    }
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }
        return () => hideTooltip()
    }, [page, post])

    return <section className="client">
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Listado de clientes</h1>
            <p>Consulte aquí el listado de clientes.</p>
            <div className="filter">
                <div className="field search">
                    <span className="material-icons">search</span>
                    <input type="text" name="s" placeholder="Búsqueda rápida..." autoComplete="off" value={post?.s || ''} onChange={updateField} />
                </div>
                <div className="in-line">
                    <div className="field channel">
                        <label htmlFor="channel">Canal:</label>
                        <select name="channel" value={post?.channel || ''} onChange={updateField}>
                            <option value="">Todos</option>
                            {channels.map((c, k) => {
                                let _r = ''
                                if(c.subchannels)
                                    return <optgroup key={k} label={c.channel}>{c.subchannels.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}</optgroup>
                                else
                                    return <option key={k} value={c.id}>{c.name}</option>
                            })}
                        </select>
                    </div>
                    <div className="field sort">
                        <label htmlFor="sort">Ordenar por:</label>
                        <select name="sort" value={post?.sort || ''} onChange={updateField}>
                            <option value="nit">NIT</option>
                            <option value="name">Nombre</option>
                            <option value="email">Correo electrónico</option>
                        </select>
                        <span className="material-icons">filter_alt</span>
                    </div>
                    <label htmlFor="inverse" className={`field sort-inverse ${post?.inverse}`}>
                        <span className="material-icons">arrow_drop_down</span>
                        <input type="checkbox" name="inverse" value="desc" checked={post?.inverse || ''} onChange={updateField} />
                    </label>
                </div>
            </div>
            {(clients?.length > 0 && <table className="list">
                <thead>
                    <tr>
                        <th>NIT</th>
                        <th>Razón social</th>
                        <th>Subcanal</th>
                        <th>Correo electrónico</th>
                        <th className="right" width="140">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {clients?.map((u) => <tr key={u.id}>
                        <td>{u.visibility == 2 && <span className="material-icons revision">fiber_new</span>}{u.nit}</td>
                        <td>{u.name}</td>
                        <td>{u.channel && checkChannel(u.channel)?.name || ''}</td>
                        <td>{u.email}</td>
                        <td className="right">
                            <Link className="button" to={`/clientes/editar/${u.id}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Ver cliente"><span className="material-icons">{u.visibility == 0 && user?.type > 1 ? 'visibility' : 'edit'}</span></Link>
                            {u.visibility == 2 || user?.type < 2 ? <a className="button" onClick={() => update(u.id, 'delete')} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Eliminar cliente"><span className="material-icons">close</span></a> : null}
                        </td>
                    </tr>)}
                </tbody>
            </table>) || <table className="list">
                <thead>
                    <tr>
                        <th><center>No hay clientes que cumplan con los filtros de búsqueda.</center></th>
                    </tr>
                </thead>
            </table>}
            <Pagination pages={pages} url="clientes" />
            <center>
                <p>&nbsp;</p>
                <Link className="button" to="/clientes/crear/">Crear un nuevo cliente</Link>
            </center>
        </div>
    </section>
}
export default Client