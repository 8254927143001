import '../styles/login.sass'
import logo from '../image/incomer.png'
import { toast, Flip } from 'react-toastify'
import { useState, useContext } from 'react'
import { AppContext } from '../context/AppContext'

const Login = () => {
    const [ form, setForm ] = useState(false)
    const { loading, updateData } = useContext(AppContext)
    const [ post, setPost ] = useState({ email: '', password: '' })
    
    const changeForm = () => setForm(!form)
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        updateData('user', 'login', formData)
    }
    const doRecover = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        updateData('user', 'recover', formData, data => {
            if(data.error)
                toast.error(data.error, { theme: "colored", transition: Flip })
            else
                toast.success(data.updated, { theme: "colored", transition: Flip })
        })
    }

    return <section className={'login'+(form && ' recover' || '')}>
        <form id="login" className="content" onSubmit={doUpdate}>
            <img src={logo} alt="Incomer" />
            <h1>Plataforma Comercial</h1>
            <div className="field">
                <label htmlFor="email">Correo electrónico</label>
                <input type="email" name="email" value={post?.email || ''} onChange={updateField} required />
            </div>
            <div className="field">
                <label htmlFor="pass">Contraseña</label>
                <input type="password" name="password" value={post?.password || ''} onChange={updateField} required autoComplete="off" />
            </div>
            <button className="button" type="submit" disabled={loading}>Iniciar sesión</button>
            <div className="field justify-center">
                <a className="white" href="#" onClick={changeForm}>¿Olvidaste tu contraseña?</a>
            </div>
        </form>
        <form id="recover" className="content" onSubmit={doRecover}>
            <img src={logo} alt="Incomer" />
            <h1>Plataforma Comercial</h1>
            <p className="field">
                Al ingresar su correo electrónico recibirá un mensaje con un enlace donde podrá ingresar una nueva contraseña.
            </p>
            <div className="field">
                <label htmlFor="email_recover">Correo electrónico</label>
                <input type="email" name="email_recover" value={post?.email_recover || ''} onChange={updateField} required />
            </div>
            <button className="button" type="submit" disabled={loading}>Recuperar</button>
            <div className="field justify-center">
                <a className="white" href="#" onClick={changeForm}>Iniciar sesión</a>
            </div>
        </form>
    </section>
}
export default Login