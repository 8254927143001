import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const InputSearch = ({ type, name, search, onChange, value = '', required = false, readonly = false, disabled = false, placeholder = '', className = '' }) => {
    let abort = null
    const { apiURL } = useContext(AppContext)
    const [ post, setPost ] = useState(value)
    const [ results, setResults ] = useState([])

    const searchData = e => {
        setPost(e.target.value)
        if(e.target.value.length > 1){
            if(abort)
                abort.abort()
            abort = new AbortController()
            let formData = new FormData()
            formData.append('s', e.target.value)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}${search}`, {signal: abort.signal, method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(data.error)
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setResults(data)
                })
        }else
            setResults([])
        onChange({target: {name, value: e.target.value, data: {}}})
    }
    const setData = (value, data) => {
        setPost(value)
        searchData({target: {value: value}})
        onChange({target: {name, value: value, data: data}})
    }

    useEffect(() => {
        if(!post && value || value === '')
            setPost(value)
    }, [value])

    return <>
        <input className={className} type={type} name={name} value={post} placeholder={placeholder} onChange={searchData} required={required} disabled={disabled} readOnly={readonly} autoComplete="off" />
        <div className="search-results">
            {results?.map((r, i) => <a key={i} onClick={() => setData(r.result, r)}>{!r.stock || r.stock == 1 ? r.label : r.label+' (No disponible)'}</a>)}
        </div>
    </>
}
export default InputSearch