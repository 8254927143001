import '../styles/order.sass'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { postventa_subject } from '../context/Variables'
import { useState, useEffect, useContext, useCallback } from 'react'

const Postventa = () => {
    const [ requests, setRequests ] = useState(null)
    const [ post, setPost ] = useState({s: '', sort: 'date'})
    const [ filteredRequests, setFilteredRequests ] = useState([])
    const { load, showTooltip, hideTooltip, apiURL } = useContext(AppContext)

    const updateField = useCallback((e) => {
        let _post = {...post}
        if(e.target.type == 'checkbox' && !e.target.checked)
            _post[e.target.name] = ''
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }, [post])

    useEffect(() => {
        if(requests === null && localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}postventa/list/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setRequests(data)
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }else if(requests !== null){
            const _filteredRequests = requests?.filter((p) => {
                return (post.s === '' || p.name.toLowerCase().includes(post.s.toLowerCase()) || postventa_subject.find(s => s.name.toLowerCase().includes(post.s.toLowerCase())))
            })
            _filteredRequests.sort((a, b) => {
                let compare = ['id', 'impact'].includes(post.sort) ? a[post.sort] - b[post.sort] : a[post.sort].localeCompare(b[post.sort])
                if(post.inverse == 'desc')
                    compare = compare * -1
                return compare
            })
            setFilteredRequests(_filteredRequests)
        }
        return () => hideTooltip()
    }, [requests, post])
    
    return <section className="postventa">
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Listado de solicitudes postventa</h1>
            <p>Consulte aquí las solicitudes realizadas por los comerciales.</p>
            <div className="filter">
                <div className="field search">
                    <span className="material-icons">search</span>
                    <input type="text" name="s" placeholder="Búsqueda rápida..." autoComplete="off" value={post?.s || ''} onChange={updateField} />
                </div>
                <div className="in-line">
                    <div className="field sort">
                        <label htmlFor="sort">Ordenar por:</label>
                        <select name="sort" value={post?.sort || ''} onChange={updateField}>
                            <option value="date">Fecha de visita</option>
                            <option value="id">ID</option>
                            <option value="name">Cliente</option>
                            <option value="agent">Comercial</option>
                            <option value="subject">Motivo</option>
                        </select>
                        <span className="material-icons">filter_alt</span>
                    </div>
                    <label htmlFor="inverse" className={`field sort-inverse ${post?.inverse}`}>
                        <span className="material-icons">arrow_drop_down</span>
                        <input type="checkbox" name="inverse" value="desc" checked={post?.inverse || ''} onChange={updateField} />
                    </label>
                </div>
            </div>
            {(filteredRequests.length > 0 && <table className="list">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>Cliente</th>
                        <th>Comercial</th>
                        <th>Motivo</th>
                        <th>Fecha de visita</th>
                        <th className="right">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {filteredRequests.map(p => <tr key={p.id}>
                        <td className="monospace">{p.id}</td>
                        <td>{p.name}</td>
                        <td>{p.agent}</td>
                        <td>{postventa_subject.find(s => s.id == p.subject).name}</td>
                        <td>{p.date}</td>
                        <td className="right">
                            <Link className="button" to={`/postventa/${p.id}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Ver solicitud"><span className="material-icons">visibility</span></Link>
                        </td>
                    </tr>)}
                </tbody>
            </table>) || <table className="list">
                <thead>
                    <tr>
                        <th><center>No hay solicitudes que cumplan con los filtros de búsqueda.</center></th>
                    </tr>
                </thead>
            </table>}
        </div>
    </section>
}
export default Postventa