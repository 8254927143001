import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const AddArea = ({update, remove, area}) => {
    const [ post, setPost ] = useState(area)
    const { updateData } = useContext(AppContext)

    const setId = (data) => {
        setPost({...post, id: data.id})
        toast.success(data.updated, { theme: "colored", transition: Flip })
    }
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('area', 'update', formData, setId)
    }

    useEffect(() => update(post), [post])

    return <tr>
        <td><div className="field"><input type="text" name="name" value={post?.name || ''} placeholder="Ingrese el nombre del área" autoComplete="off" onChange={updateField} required /></div></td>
        <td><div className="field"><input type="email" name="email" value={post?.email || ''} placeholder="Ingrese el correo electrónico del área" autoComplete="off" onChange={updateField} required /></div></td>
        <td className="right"><a className="button" onClick={doUpdate}>Guardar</a><a className="button" onClick={() => remove(post?.n, post?.id)}><span className="material-icons">close</span></a></td>
    </tr>
}
export default AddArea