import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { getImageData } from '../hook/GetImageData'
import { useState, useEffect, useContext, useRef } from 'react'

const AddUser = ({n, add, update, remove, user, single}) => {
    const imageButton = useRef(null)
    const [ post, setPost ] = useState(user)
    const { updateData } = useContext(AppContext)

    const setId = (data) => {
        setPost({...post, id: data.id})
        toast.success(data.updated, { theme: "colored", transition: Flip })
    }
    const updateField = (e) => {
        if(e.target.name === 'image_file')
            getImageData(e.target.files[0], 'image', post, setPost)
        else
            setPost({...post, [e.target.name]: e.target.value})
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('user', 'update', formData, setId)
    }

    useEffect(() => update(post), [post])

    return <div className="add-block">
        <div>
            <h6>Usuario {user?.id ? `"${user?.full_name}"` : '# '+n}</h6>
            {!single && <a className="add" href="#" onClick={add}><span className="material-icons">add_circle</span> Agregar otro usuario</a>}
        </div>
        <a className="remove" href="#" onClick={() => remove(post?.n, post?.id)}>Eliminar usuario<span className="material-icons">do_disturb_on</span></a>
        <form onSubmit={doUpdate}>
            <div className="field">
                <label htmlFor="type">Tipo de usuario</label>
                <select name="type" value={post?.type || ''} onChange={updateField} required>
                    <option value="">Seleccionar</option>
                    <option value="1">Administrador</option>
                    <option value="2">Comercial</option>
                </select>
            </div>
            <div className="field">
                <label htmlFor="full_name">Nombre completo</label>
                <input type="text" name="full_name" value={post?.full_name || ''} autoComplete="off" onChange={updateField} required />
            </div>
            <div className="field">
                <label htmlFor="document"># de identificación</label>
                <input type="number" name="document" value={post?.document || ''} autoComplete="off" onChange={updateField} required />
            </div>
            <div className="field">
                <label htmlFor="email">Correo electrónico</label>
                <input type="email" name="email" value={post?.email || ''} autoComplete="off" onChange={updateField} required />
            </div>
            <div className="field image">
                <label className="w-100" htmlFor="image_file">Foto de perfil</label>
                <input type="hidden" name="image" value={post?.image || ''} required />
                <input ref={imageButton} className="hide" type="file" name="image_file" onChange={updateField} />
                <button className="button gray" onClick={(e) => { e.preventDefault(); imageButton.current.click(); }}>Subir imágen</button>
                <img className={(post?.image && 'profile') || 'hide'} src={post?.image || ''} alt={`Foto de perfil ${post?.full_name}`} />
            </div>
            <div className="field">
                <label className="w-100">&nbsp;</label>
                <button className="button" type="submit">Guardar</button>
            </div>
        </form>
    </div>
}
export default AddUser