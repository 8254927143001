import '../styles/product.sass'
import AddProduct from './AddProduct'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

const Product = () => {
    const navigate = useNavigate()
    const { action, id } = useParams()
    const [ products, setProducts ] = useState([])
    const [ scrollTop, setScrollTop ] = useState(0)
    const { main, updateData, apiURL } = useContext(AppContext)

    const addProduct = () => {
        setScrollTop(main.current.scrollTop)
        setProducts([...products, {n: products.at(-1).n + 1}])
    }
    const updateProduct = (data) => {
        setScrollTop(main.current.scrollTop)
        setProducts(() => {
            const _index = products.findIndex(p => p.n == data.n)
            const _products = [...products]
            _products[_index] = data
            return _products
        })
    }
    const removeProduct = (key, id) => {
        setScrollTop(main.current.scrollTop)
        if(id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            updateData('product', 'delete', formData, (data) => {
                setProducts(products.filter(p => p.n != key))
                toast.warning(data.updated, { theme: "colored", transition: Flip })
            })
        }else
            setProducts(products.filter(p => p.n != key))
    }

    useEffect(() => {
        if(products.length == 0 && action == 'editar'){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}product/get/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined'){
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    }else{
                        console.log(data)
                        setProducts([data])
                        if(!data.id)
                            navigate('/productos/')
                    }
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }else if(products.length == 0)
            setProducts([{n: 1}])
        if(main.current.scrollTop == 0)
            main.current.scrollTop = scrollTop
    }, [scrollTop, products])

    return <section className="product">
        <div className="content">
            <Link className="go-back" to="/productos/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>{action == 'editar' ? 'Editar producto' : 'Creación de productos'}</h1>
            <p>Diligencie el formulario con los datos {action == 'editar' ? 'del producto.' : 'de los productos que desea crear'}</p>
            <h5>Información del producto</h5>
            {products.map((p, i) => <AddProduct key={p.n || i} n={i+1} add={addProduct} update={updateProduct} remove={removeProduct} product={p} single={id} />)}
        </div>
    </section>
}
export default Product