import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { pqrs_states } from '../context/Variables'
import { useState, useEffect, useContext, useRef } from 'react'

const AddState = ({n, update, add, state, length = 0}) => {
    const form = useRef(null)
    const formButton = useRef(null)
    const [ post, setPost ] = useState(state)
    const [ areas, setAreas ] = useState(null)
    const { user, updateData, apiURL } = useContext(AppContext)

    const doDropdown = e => {
        const isActive = e.target.parentElement.classList.contains('open')
        document.querySelectorAll('.dropdown').forEach((el) => el.classList.remove('open'));
        if(!isActive)
            e.target.parentElement.classList.add('open')
    }
    const updateField = e => {
        let _post = {...post}
        _post[e.target.name] = e.target.value
        if(e.target.name == 'area')
            _post.email = areas.find(a => a.id == e.target.value).email
        setPost(_post)
    }
    const doUpdate = e => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('pqrs', 'update', formData, (data) => {
            if(post.state == 1)
                add()
            setPost({...post, id: data.id})
            toast.success(data.updated, { theme: "colored", transition: Flip })
        })
    }

    useEffect(() => {
        if(areas === null){
            let formData = new FormData()
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}areas/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setAreas(data)
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }
        update(post)
    }, [post])
    
    return <div className={'add-block dropdown'+(n == length && ' open last' || '')}>
        <a className="dropdown-area" onClick={doDropdown}></a>
        <h6>Estado / Asignación {n == length && !post?.id ? 'nueva' : '# '+n}</h6>
        <form ref={form} onSubmit={doUpdate}>
            <div className="field">
                <label htmlFor="area">Area</label>
                <select name="area" value={post?.area || ''} onChange={updateField} required readOnly={post?.id} disabled={post?.id}>
                    <option value="">Seleccionar</option>
                    {areas?.length > 0 && areas?.map(a => <option key={a.id} value={a.id}>{a.name}</option>)}
                </select>
            </div>
            <div className="field">
                <label>Correo electrónico</label>
                <input type="text" value={post?.email || ''} placeholder="Debe seleccionar un área" autoComplete="off" readOnly disabled />
            </div>
            <div className="field">
                <label htmlFor="state">Estado</label>
                <select name="state" value={post?.state || ''} onChange={updateField} required readOnly={post?.id} disabled={post?.id}>
                    <option value="">Seleccionar</option>
                    {pqrs_states.map((s, k) => <option key={k} value={s.id}>{s.name}</option>)}
                </select>
            </div>
            <div className="field w-100">
                <label htmlFor="message">Observaciones</label>
                <textarea name="message" value={post?.message || ''} onChange={updateField} required readOnly={post?.id} disabled={post?.id}></textarea>
            </div>
            {!post?.id &&  <button className="button" type="submit">Guardar</button> || null}
        </form>
    </div>
}
export default AddState