import '../styles/user.sass'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import AddFreeDays from '../action/AddFreeDays'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const FreeDays = () => {
    const [ scrollTop, setScrollTop ] = useState(0)
    const [ freedays, setFreedays ] = useState(null)
    const { main, load, updateData, apiURL } = useContext(AppContext)

    const doDropdown = e => {
        const isActive = e.target.parentElement.classList.contains('open')
        document.querySelectorAll('.dropdown').forEach((el) => el.classList.remove('open'));
        if(!isActive)
            e.target.parentElement.classList.add('open')
    }
    const addFreeDays = () => {
        setScrollTop(main.current.scrollTop)
        setFreedays([...freedays, {n: freedays.at(-1).n + 1}])
    }
    const updateFreedays = (data) => {
        setScrollTop(main.current.scrollTop)
        setFreedays(() => {
            const _index = freedays.findIndex(p => p.n == data?.n)
            const _freedays = [...freedays]
            _freedays[_index] = data
            return _freedays
        })
    }
    const removeFreedays = (key, id) => {
        setScrollTop(main.current.scrollTop)
        if(id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            updateData('freeday', 'delete', formData, (data) => {
                setFreedays(freedays.filter(p => p.n != key))
                toast.warning(data.updated, { theme: "colored", transition: Flip })
            })
        }else
            setFreedays(freedays.filter(p => p.n != key))
    }
    
    useEffect(() => {
        if(freedays === null && localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}freedays/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setFreedays(data)
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }else if(freedays.length == 0)
            setFreedays([{n: 1}])
        if(main.current.scrollTop == 0)
            main.current.scrollTop = scrollTop
    }, [scrollTop, freedays])

    return <div className="content py-0 dropdown" style={{'--dropdown-height': (440 + (freedays?.length * 80))+'px'}}>
        <a className="dropdown-area" onClick={doDropdown}></a>
        <h1>Días no laborales</h1>
        <p>Consulte y administre aquí los días laborales.</p>
        {(freedays?.length > 0 && <table className="list">
            <thead>
                <tr>
                    <th>Descripción</th>
                    <th>Condición</th>
                    <th>Día / Desde</th>
                    <th>Hasta (opcional)</th>
                </tr>
            </thead>
            <tbody>
                {freedays.map(f => <AddFreeDays key={f.n} update={updateFreedays} remove={removeFreedays} freeday={f} />)}
            </tbody>
        </table>) || <table className="list">
            <thead>
                <tr>
                    <th><center>No hay días no laborales que cumplan con los filtros de búsqueda.</center></th>
                </tr>
            </thead>
        </table>}
        <center>
            <a className="button" href="#" onClick={addFreeDays}>Crear una nueva área</a>
            <p>&nbsp;</p>
        </center>
    </div>
}
export default FreeDays