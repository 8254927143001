import '../styles/user.sass'
import Popup from '../layout/Popup'
import AddRequest from './AddRequest'
import DatePicker from 'react-datepicker'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import 'react-datepicker/dist/react-datepicker.css'
import InputSearch from '../components/InputSearch'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { orderStatus, cities, zones } from '../context/Variables'

const Client = () => {
    const form = useRef(null)
    const navigate = useNavigate()
    const createPopup = useRef(null)
    const formButton = useRef(null)
    const { action, id } = useParams()
    const [ post, setPost ] = useState({})
    const [ requests, setRequests ] = useState([])
    const [ scrollTop, setScrollTop ] = useState(0)
    const [ freedays, setFreedays ] = useState(null)
    const [ validate, setValidate ] = useState({index: -1, send: false})
    const { user, main, updateData, checkChannel, apiURL } = useContext(AppContext)

    const validated = valid => {
        if(valid){
            let _requests = [...requests]
            const _index = requests.findIndex(r => !r.validation)
            _requests[_index].validation = 1
            setRequests(_requests)
            if(_requests.findIndex(r => !r.validation) == -1)
                setValidate({index: -1, send: true, date: Date.now()})
            else
                setValidate({index: _requests.findIndex(r => !r.validation), send: false, date: Date.now(), validated})
        }else
            setValidate({index: -1, send: false})
    }
    const updateField = (e) => {
        let _post = {...post}
        if(e.target.name == 'nit'){
            _post['name'] = e.target.data.name
            _post['client'] = e.target.data.id
            _post['email'] = e.target.data.email
            _post['phone'] = e.target.data.phone
            _post['wallet'] = e.target.data.wallet
            _post['status'] = e.target.data.status
            _post['channel'] = e.target.data.channel
        }else if(e.target.name == 'code'){
            _post['point'] = e.target.data.id
            _post['city'] = e.target.data.city
            _post['address'] = e.target.data.address && e.target.data.address+(e.target.data.zone && ' - '+zones.find(z => z.id == e.target.data.zone)?.name || '') || ''
        }else if(e.target.name == 'agent'){
            _post['agent_id'] = e.target.data.id
        }
        _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        const _index = requests.findIndex(r => !r.validation)
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        if(!id || validate.send){
            if(form.current.reportValidity()){
                updateData('order', 'update', formData, data => {
                    if(!id){
                        toast.success(data.updated, { theme: "colored", transition: Flip })
                        navigate(`/pedidos/editar/${data.id}/`)
                    }else
                        createPopup.current.classList.add('active')
                })
                setValidate({index: -1, send: false, date: Date.now()})
            }
        }else if(_index === -1)
            setValidate({index: -1, send: true, date: Date.now()})
        else
            setValidate({index: _index, send: false, date: Date.now(), validated})
    }
    const removeOrder = (id) => {
        let formData = new FormData()
        formData.append('id', id)
        formData.append('status', 9)
        formData.append('token', localStorage.getItem('userToken'))
        updateData('order', 'status', formData, (data) => {
            toast.warning(data.updated, { theme: "colored", transition: Flip })
        })
    }
    const addRequest = () => {
        setScrollTop(main.current.scrollTop)
        setRequests([...requests, {n: requests.at(-1).n + 1, order: id, validation: 0}])
    }
    const updateRequest = (data) => {
        setScrollTop(main.current.scrollTop)
        setRequests(() => {
            const _index = requests.findIndex(p => p.n == data?.n)
            const _requests = [...requests]
            _requests[_index] = data
            return _requests
        })
    }
    const removeRequest = (key, id) => {
        setScrollTop(main.current.scrollTop)
        if(id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            updateData('request', 'delete', formData, (data) => {
                setRequests(requests.filter(p => p.n != key))
                toast.warning(data.updated, { theme: "colored", transition: Flip })
            })
        }else
            setRequests(requests.filter(p => p.n != key))
    }

    useEffect(() => {
        if(validate.send)
            formButton.current.click()
        if(freedays === null && localStorage.getItem('userToken')){
            let formData = new FormData()
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}freedays/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setFreedays(data)
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => {})
        }
        if(action == 'por-cliente'){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}order/byclient/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        data.new = true
                        data.dpdate = new Date(data.date);
                        setPost(data)
                        setRequests([])
                        setScrollTop(0)
                        setValidate({index: -1, send: false})
                        createPopup.current.classList.remove('active')
                        navigate('/pedidos/crear/')
                    }
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }else if(action == 'crear' && post?.new !== true && (Object.keys(post).length > 0 || requests.length > 0 || validate.index != -1 || validate.send)){
            setPost({})
            setRequests([])
            setScrollTop(0)
            setValidate({index: -1, send: false})
            createPopup.current.classList.remove('active')
        }else if(requests.length == 0 && action == 'editar'){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}order/get/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        data.dpdate = new Date(data.date+'T12:00:00+00:00');
                        setPost(data)
                        setRequests(data.requests.length > 0 ? data.requests : [{n: 1, order: id, validation: 0}])
                        if(!data.id)
                            navigate('/pedidos/')
                    }
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }else if(requests.length == 0 && id)
            setRequests([{n: 1, order: id}])
        if(user?.typelogin == 'client'){
            console.log('User: ', user)
            setPost({...post, channel: user.channel, client: user.id, email: user.email, name: user.full_name, nit: user.nit, phone: user.phone, status: user.status, wallet: user.wallet})
        }
        if(main.current.scrollTop == 0)
            main.current.scrollTop = scrollTop
    }, [user, scrollTop, id, requests, validate, action])

    console.log(post)

    return <section className="order">
        <Popup use={createPopup}>
            <h2 className="text-center">¡Creación exitosa!</h2>
            <p className="text-center">Qué le gustaría hacer ahora</p>
            <hr />
            {post?.agent_id && <>
                <Link className="big-button" to={`/pedidos/por-cliente/${id}`}><span className="material-icons">done</span> Agregar otro pedido para el mismo cliente</Link>
                <Link className="big-button" to="/pedidos/crear/"><span className="material-icons">done</span> Nuevo pedido para otro cliente</Link>
            </> || null}
            <Link className="big-button" to="/pedidos/"><span className="material-icons">done</span> Finalizar</Link>
        </Popup>
        <div className="content">
            <Link className="go-back" to="/pedidos/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>{action == 'editar' ? 'Editar pedido' : 'Creación de pedido'}</h1>
            <p>Diligencie el formulario con los datos del pedido.</p>
            <h5>Información del cliente</h5>
            <form ref={form} onSubmit={doUpdate} className="parent-block">
                {post?.status && <div className="field wallet">
                    <strong>Estado de la cartera:</strong><span>{post.status}</span>
                </div> || ''}
                {post?.wallet && <div className="field wallet">
                    <strong>Disponible:</strong><span>{(+post.wallet).toLocaleString('es-CO', { style: 'currency', currency: 'COP', minimumFractionDigits: 0, maximumFractionDigits: 0 })}</span>
                </div> || ''}
                {(user?.type < 2 && <>
                    <div className="field w-66">
                        <label htmlFor="agent">Comercial</label>
                        <InputSearch className={(!post?.agent || !post?.agent_id) && 'required' || ''} type="text" name="agent" value={post?.agent || ''} placeholder="Buscar comerciales..." onChange={updateField} required={true} search="user/search/" />
                        <input type="hidden" name="agent_id" value={post?.agent_id || ''} required />
                    </div>
                    <div className="field">
                        <label htmlFor="order_status">Estado de la orden</label>
                        <select name="order_status" value={post?.order_status || 1} onChange={updateField} required>
                            {orderStatus.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}
                        </select>
                    </div>
                </>) || null}
                <div className="field">
                    <label htmlFor="nit">NIT / CC</label>
                    <InputSearch className={(!post?.nit || !post?.name) && 'required' || ''} type="number" name="nit" value={post?.nit || ''} placeholder="Buscar clientes..." onChange={updateField} required={true} readOnly={user?.typelogin == 'client' || id} disabled={user?.typelogin == 'client' || id} search="client/search/" />
                    <input type="hidden" name="client" value={post?.client || ''} required />
                </div>
                <div className="field">
                    <label>Razón social</label>
                    <input type="text" value={post?.name || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Subcanal</label>
                    <input type="text" value={post?.channel && checkChannel(post?.channel)?.name || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Correo electrónico</label>
                    <input type="text" value={post?.email || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Teléfono</label>
                    <input type="text" value={post?.phone || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label htmlFor="code">Código de sucursal</label>
                    <InputSearch className={(!post?.code || !post?.city) && 'required' || ''} type="number" name="code" value={post?.code || ''} placeholder="Buscar sucursales..." onChange={updateField} required={true} readOnly={id} disabled={id || !post?.name} search={`point/search/${post?.client}/`} />
                </div>
                <div className="field">
                    <label>Ciudad</label>
                    <input type="text" value={post?.city && cities.find(c => post.city == c.id)?.name || ''} placeholder="Ingresa código y selecciona sucursal" readOnly disabled />
                    <input className="hidden" type="text" name="point" value={post?.point || ''} onChange={e => false} required />
                </div>
                <div className="field">
                    <label>Dirección de sucursal - Zona</label>
                    <input type="text" value={post?.address || ''} placeholder="Ingresa código y selecciona sucursal" readOnly disabled />
                </div>
                <div className="field">
                    <label htmlFor="date">Fecha de entrega</label>
                    <DatePicker className={!post?.date && 'required' || ''} onChange={date => setPost({...post, date: date.toISOString().split('T')[0], dpdate: date})} isClearable selected={post?.dpdate} startDate={post?.dpdate} dateFormat="dd/MM/yyyy" filterDate={date => {
                        let _ret = true
                        freedays.map(f => {
                            const fdate = date.toISOString().split('T')[0]
                            if(date <= new Date())
                                _ret = false
                            if(+f.cond < 7 && date.getDay() === +f.cond)
                                _ret = false
                            if(+f.cond === 7 && f.to === '' && f.from === fdate)
                                _ret = false
                            if(+f.cond === 7 && f.from <= fdate && f.to >= fdate)
                                _ret = false
                        })
                        return _ret
                    }} />
                </div>
                <div className="field w-100">
                    <label htmlFor="message">Comentarios</label>
                    <textarea className={!post?.message && 'required' || ''} name="message" value={post?.message || ''} onChange={updateField} required readOnly={user?.type > 1 && post?.order_status && post?.order_status != 0} disabled={user?.type > 1 && post?.order_status && post?.order_status != 0}></textarea>
                </div>
            </form>
            <div className="parent-block">
                {id && <>
                    <h5>Información del pedido</h5>
                    {requests.length > 0 && requests?.map((p, i) => <AddRequest key={p.n} n={i+1} add={addRequest} update={updateRequest} remove={removeRequest} validate={(i === validate.index || validate.send) && validate} request={p} length={requests.length} readOnly={user?.type > 1 && post?.order_status && post?.order_status != 0} />)}
                </> || null}
                {(user?.type < 2 || !post?.order_status || post?.order_status == 0) && <div className="w-100 text-center">
                    {id && <button className="button gray" onClick={() => removeOrder(post?.id)}>Anular</button> || ''}
                    <button ref={formButton} onClick={doUpdate} className="button">{id && 'Actualizar' || 'Guardar'}</button>
                </div> || ''}
            </div>
        </div>
    </section>
}
export default Client