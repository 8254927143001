import Header from './Header'
import Pqrs from '../section/Pqrs'
import User from '../section/User'
import Task from '../section/Task'
import DoUser from '../action/User'
import DoPqrs from '../action/Pqrs'
import DoTask from '../action/Task'
import Login from '../section/Login'
import Order from '../section/Order'
import DoOrder from '../action/Order'
import Client from '../section/Client'
import DoClient from '../action/Client'
import Profile from '../action/Profile'
import Product from '../section/Product'
import DoProduct from '../action/Product'
import Contexts from '../context/Contexts'
import Settings from '../section/Settings'
import Postventa from '../section/Postventa'
import Dashboard from '../section/Dashboard'
import DoPostventa from '../action/Postventa'
import { ToastContainer } from 'react-toastify'
import { Routes, Route } from 'react-router-dom'

const App = () => {
    return <Contexts>
            <Header />
            <Routes>
                <Route path="/" element={<Dashboard />} />

                <Route path="/usuarios" element={<User />} />
                <Route path="/usuarios/:action" element={<DoUser />} />
                <Route path="/usuarios/page/:page" element={<User />} />
                <Route path="/usuarios/:action/:id" element={<DoUser />} />

                <Route path="/productos/" element={<Product />} />
                <Route path="/productos/:action" element={<DoProduct />} />
                <Route path="/productos/page/:page" element={<Product />} />
                <Route path="/productos/:action/:id" element={<DoProduct />} />

                <Route path="/clientes/" element={<Client />} />
                <Route path="/clientes/:action" element={<DoClient />} />
                <Route path="/clientes/page/:page" element={<Client />} />
                <Route path="/clientes/:action/:id" element={<DoClient />} />

                <Route path="/pedidos/" element={<Order />} />
                <Route path="/pedidos/:action" element={<DoOrder />} />
                <Route path="/pedidos/page/:page" element={<Order />} />
                <Route path="/pedidos/:action/:id" element={<DoOrder />} />

                <Route path="/pqrs/" element={<Pqrs />} />
                <Route path="/pqrs/page/:page" element={<Pqrs />} />
                <Route path="/pqrs/:action" element={<DoPqrs />} />
                <Route path="/pqrs/:action/:id" element={<DoPqrs />} />

                <Route path="/postventa/" element={<Postventa />} />
                <Route path="/postventa/:id" element={<DoPostventa />} />
                <Route path="/postventa/page/:page" element={<Postventa />} />
                <Route path="/solicitud-postventa/" element={<DoPostventa />} />

                <Route path="/tareas/" element={<Task />} />
                <Route path="/tareas/sperity/" element={<Task />} />
                <Route path="/tareas/page/:page" element={<Task />} />
                <Route path="/tareas/postventa/" element={<Task />} />
                <Route path="/tareas/:action/:id" element={<DoTask />} />

                <Route path="/configuracion/" element={<Settings />} />
                <Route path="/perfil/" element={<Profile />} />
                <Route path="/perfil/:token" element={<Profile />} />
                <Route path="/iniciar-sesion/" element={<Login />} />
            </Routes>
        <ToastContainer position="bottom-center" autoClose="2500" pauseOnFocusLoss={false} newestOnTop closeOnClick enableMultiContainer />
        <div id="tooltip"><div className="text"></div></div>
    </Contexts>
}
export default App