import { zones } from '../context/Variables'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const AddClient = ({n, add, update, remove, point}) => {
    const [ post, setPost ] = useState(point)
    const [ cities, setCities ] = useState(null)
    const { updateData, apiURL } = useContext(AppContext)

    const setId = (data) => {
        setPost({...post, id: data.id})
        toast.success(data.updated, { theme: "colored", transition: Flip })
    }
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('point', 'update', formData, setId)
    }

    useEffect(() => {
		if(cities === null && localStorage.getItem('userToken')){
			let formData = new FormData()
			formData.append('token', localStorage.getItem('userToken'))
			fetch(`${apiURL}cities/`, {method: 'POST', body: formData})
				.then(res => res.json())
				.then((data) => {
					if(typeof data.error != 'undefined')
						toast.error(data.error, { theme: "colored", transition: Flip })
					else
						setCities(data)
				})
				.catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
		}else
			update(post)
	}, [post])

    return <div className="add-block">
        <div>
            <h6>Sucursal # {n}</h6>
            <a className="add" href="#" onClick={add}><span className="material-icons">add_circle</span> Agregar otra sucursal</a>
        </div>
        <a className="remove" href="#" onClick={() => remove(post?.n, post?.id)}>Eliminar sucursal<span className="material-icons">do_disturb_on</span></a>
        <form onSubmit={doUpdate}>
            <div className="field">
                <label htmlFor="code">Código</label>
                <input type="text" name="code" value={post?.code || ''} autoComplete="off" onChange={updateField} required />
            </div>
            <div className="field">
                <label htmlFor="city">Ciudad</label>
                <select name="city" value={post?.city || ''} onChange={updateField} required>
                    <option value="">Seleccionar</option>
                    {cities?.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}
                </select>
            </div>
            <div className="field">
                <label htmlFor="address">Dirección</label>
                <input type="text" name="address" value={post?.address || ''} autoComplete="off" onChange={updateField} required />
            </div>
            <div className="field">
                <label htmlFor="zone">Zona</label>
                <select name="zone" value={post?.zone || ''} onChange={updateField} required>
                    <option value="">Seleccionar</option>
                    {zones.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}
                </select>
            </div>
            <div className="field">
                <label htmlFor="coordinator">Coordinador</label>
                <input type="text" name="coordinator" value={post?.coordinator || ''} autoComplete="off" onChange={updateField} />
            </div>
            <div className="field">
                <label htmlFor="phone">Número de contacto</label>
                <input type="text" name="phone" value={post?.phone || ''} autoComplete="off" onChange={updateField} />
            </div>
            <div className="field">
                <label className="w-100">&nbsp;</label>
                <button className="button" type="submit">Guardar</button>
            </div>
        </form>
    </div>
}
export default AddClient