import Area from './Area'
import City from './City'
import Export from './Export'
import Uploads from './Uploads'
import FreeDays from './FreeDays'
import '../styles/settings.sass'

const Settings = () => {
    return <section className="settings">
        <Export />
        <hr className="line-60" />
        <Uploads />
        <hr className="line-60" />
        <Area />
        <hr className="line-60" />
        <City />
        <hr className="line-60" />
        <FreeDays />
		<hr className="line-60" />
    </section>
}
export default Settings