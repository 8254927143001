import '../styles/user.sass'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useContext, useRef } from 'react'

const Export = () => {
    const [ post, setPost ] = useState({})
    const { apiURL } = useContext(AppContext)

    const doDropdown = e => {
        const isActive = e.target.parentElement.classList.contains('open')
        document.querySelectorAll('.dropdown').forEach((el) => el.classList.remove('open'));
        if(!isActive)
            e.target.parentElement.classList.add('open')
    }
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        //e.preventDefault()
        let variables = '';
        if(post.type == 'orders')
            variables = `${post.format}/${post.active}/${post.from}/${post.to}`
        e.target.action = `${apiURL}export/${post.type}/${variables}`;
    }

    return <div className="content py-0 mt-60 dropdown">
        <a className="dropdown-area" onClick={doDropdown}></a>
        <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
        <h1>Exportar información</h1>
        <p>Seleccione el tipo de datos que desea exportar.</p>
        <h5>Información a exportar</h5>
        <form className="parent-block" method="POST" target="_blank" onSubmit={doUpdate}>
            <input type="hidden" name="token" value={localStorage.getItem('userToken')} />
            <div className="field">
                <label htmlFor="type">Tipo de exportación</label>
                <select name="type" value={post?.type || ''} onChange={updateField} required>
                    <option value="">Seleccionar</option>
                    <option value="clients">Clientes</option>
                    <option value="orders">Pedidos</option>
                </select>
            </div>
            {post?.type == 'orders' && <>
                <div className="field">
                    <label htmlFor="format">Formato</label>
                    <select name="format" value={post?.format || ''} onChange={updateField} required>
                        <option value="">Seleccionar</option>
                        <option value="areas">Áreas</option>
                        <option value="agents">Comerciales</option>
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="active">Pedidos inactivos</label>
                    <select name="active" value={post?.active || ''} onChange={updateField} required>
                        <option value="active">No</option>
                        <option value="all">Si</option>
                    </select>
                </div>
                <div className="field">
                    <label htmlFor="from">Desde</label>
                    <input type="date" name="from" value={post?.from || ''} autoComplete="off" onChange={updateField} required />
                </div>
                <div className="field left">
                    <label htmlFor="to">Hasta</label>
                    <input type="date" name="to" value={post?.to || ''} autoComplete="off" onChange={updateField} required />
                </div>
            </> || null}
            <div className="field left">
                <label className="w-100">&nbsp;</label>
                <button className="button" type="submit">Descargar</button>
            </div>
        </form>
    </div>
}
export default Export