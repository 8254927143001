import '../styles/user.sass'
import AddUser from './AddUser'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'
import { Link, useParams, useNavigate } from 'react-router-dom'

const User = () => {
    const navigate = useNavigate()
    const { action, id } = useParams()
    const [ users, setUsers ] = useState([])
    const [ scrollTop, setScrollTop ] = useState(0)
    const { main, updateData, apiURL } = useContext(AppContext)

    const addUser = () => {
        setScrollTop(main.current.scrollTop)
        setUsers([...users, {n: users.at(-1).n + 1}])
    }
    const updateUser = (data) => {
        setScrollTop(main.current.scrollTop)
        setUsers(() => {
            const _index = users.findIndex(u => u.n == data.n)
            const _users = [...users]
            _users[_index] = data
            return _users
        })
    }
    const removeUser = (key, id) => {
        setScrollTop(main.current.scrollTop)
        if(id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            updateData('user', 'delete', formData, (data) => {
                setUsers(users.filter(p => p.n != key))
                toast.warning(data.updated, { theme: "colored", transition: Flip })
            })
        }else
            setUsers(users.filter(p => p.n != key))
    }

    useEffect(() => {
        if(users.length == 0 && action == 'editar'){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}user/get/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(data?.error)
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        setUsers([data])
                        if(!data.id)
                            navigate('/usuarios/')
                    }
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }else if(users.length == 0)
            setUsers([{n: 1}])
        if(main.current.scrollTop == 0)
            main.current.scrollTop = scrollTop
    }, [scrollTop, users])

    return <section className="user">
        <div className="content">
            <Link className="go-back" to="/usuarios/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>{action == 'editar' ? 'Editar usuario' : 'Creación de usuarios'}</h1>
            <p>Diligencie el formulario con los datos {action == 'editar' ? 'del usuario.' : 'de los usuarios que desea crear.'}</p>
            <h5>Información del usuario</h5>
            {users.map((u, i) => <AddUser key={u.n || i} n={i+1} add={addUser} update={updateUser} remove={removeUser} user={u} single={id} />)}
        </div>
    </section>
}
export default User