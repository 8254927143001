import '../styles/order.sass'
import DatePicker from 'react-datepicker'
import { toast, Flip } from 'react-toastify'
import Pagination from '../components/Pagination'
import { AppContext } from '../context/AppContext'
import { Link, useParams } from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import { cities, postventa_subject } from '../context/Variables'
import { useState, useEffect, useContext, useCallback, useRef } from 'react'

const Task = () => {
    const form = useRef(null)
    const { page = 1 } = useParams()
    const [ pages, setPages ] = useState(1)
    const [ tasks, setTasks ] = useState(null)
    const [ agents, setAgents ] = useState(null)
    const [ clients, setClients ] = useState(null)
    const pathParts = window.location.pathname.split('/')
    const { user, load, showTooltip, hideTooltip, apiURL } = useContext(AppContext)
    const postventa = pathParts.at(-1) === 'postventa' || pathParts.at(-2) === 'postventa'
    const [ post, setPost ] = useState({s: '', agent: '', client: '', type: postventa ? 'postventa' : '', from: '', to: ''})

    const updateField = useCallback((e) => {
        let _post = {...post}
        if(e.target.type == 'checkbox' && !e.target.checked)
            _post[e.target.name] = ''
        else
            _post[e.target.name] = e.target.value
        setPost(_post)
    }, [post])

    let _to = post?.to ? new Date(post.to) : '';
    let _from = post?.from ? new Date(post.from) : '';
    if(post.from)
        _from = _from.getFullYear()+'-'+(_from.getMonth()+1).toString().padStart(2, "0")+'-'+_from.getDate().toString().padStart(2, "0");
    if(post.to)
        _to = _to.getFullYear()+'-'+(_to.getMonth()+1).toString().padStart(2, "0")+'-'+_to.getDate().toString().padStart(2, "0");
    
    useEffect(() => {
        if(localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            for(let key in post)
                formData.append(key, post[key])
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}tasks/${page > 1 ? `page/${page}/` : ''}`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        setPages(data.pages)
                        setTasks(data.tasks)
                        setAgents(data.agents)
                        setClients(data.clients)
                    }
                })
                .catch(() => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }
        return () => hideTooltip()
    }, [page, post])

    return <section className="task">
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Listado de {(post.type == 'postventa' && 'solicitudes postventa') || (post.type == 'preventiva' && 'solicitud Sperity Preventiva') || (post.type == 'diagnostico' && 'solicitud Sperity Diagnóstico') || 'tareas'}</h1>
            <p>Consulte aquí las tareas creadas.</p>
            <div className="text-center desktop" style={{display: 'flex', justifyContent: 'space-between', margin: '10px 0'}}>
                {post.type != 'preventiva' && <Link className="button" to="/tareas/crear/preventiva/">Crear visita Sperity Preventiva</Link>}
                {post.type != 'diagnostico' && <Link className="button" to="/tareas/crear/preventiva/">Crear visita Sperity Diagnóstico</Link>}
                {post.type != 'postventa' && <Link className="button" to="/tareas/crear/postventa/">Crear solicitud postventa</Link>}
            </div>
            <div className="filter">
                <div className="field search">
                    <span className="material-icons">search</span>
                    <input type="text" name="s" placeholder="Búsqueda rápida..." autoComplete="off" value={post?.s || ''} onChange={updateField} />
                </div>
                <div className="in-line">
                    {user?.type < 2 && <div className="field agent">
                        <select name="agent" value={post?.agent || ''} onChange={updateField}>
                            <option value="">Todos los comerciales</option>
                            {agents?.map(a => <option key={a.id} value={a.id}>{a.full_name}</option>)}
                        </select>
                    </div> || null}
                    <div className="field">
                        <select name="client" value={post?.client || ''} onChange={updateField}>
                            <option value="">Todas las sedes</option>
                            {clients?.map(c => <option key={c.id} value={c.id}>{c.name} ({c.address})</option>)}
                        </select>
                    </div>
                    <div className="field">
                        <select name="type" value={post?.type || ''} onChange={updateField}>
                            <option value="">Todas las tareas</option>
                            {postventa_subject?.map(t => <option key={t.id} value={t.id}>{t.name}</option>)}
                        </select>
                    </div>
                    {user?.type < 2 && <div className="field">
                        <DatePicker placeholderText="Rango de fecha" onChange={date => setPost({...post, from: date[0], to: date[1]})} selectsRange isClearable selected={post?.from} startDate={post?.from} endDate={post?.to} />
                    </div>}
                    <form ref={form} method="POST" target="_blank" action={`${apiURL}export/tasks/`}>
                        <input type="hidden" name="token" value={localStorage.getItem('userToken')} />
                        <input type="hidden" name="s" value={post?.s} />
                        <input type="hidden" name="agent" value={post?.agent} />
                        <input type="hidden" name="client" value={post?.client} />
                        <input type="hidden" name="to" value={_to && _from && _to+' 23:59:59' || ''} />
                        <input type="hidden" name="from" value={_to && _from && _from+' 00:00:00' || ''} />
                        <button type="submit" className="button"><span className="material-icons">file_download</span></button>
                    </form>
                </div>
            </div>
            <div className="text-center mobile">
                <Link className="button" to="/tareas/crear/">Crear una nueva tarea</Link>
                <p>&nbsp;</p>
            </div>
            {(tasks?.length > 0 && <table className="list">
                <thead>
                    <tr>
                        <th>Cliente</th>
                        <th>Sucursal</th>
                        {user?.type < 2 && <th>Comercial</th> || null}
                        <th>Motivo</th>
                        <th>Fecha</th>
                        <th className="right" width="190">Acciones</th>
                    </tr>
                </thead>
                <tbody>
                    {tasks?.map(t => <tr key={t.id}>
                        <td>{t.name}</td>
                        <td><span onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip={(t?.city != 0 && cities.length > 0 && cities.find(c => c.id == t.city)?.name+', ' || '')+t?.address}>{t?.code}</span></td>
                        {user?.type < 2 && <td>{t.full_name}</td> || null}
                        <td>{postventa_subject.find(s => s.id == t.subject)?.name}</td>
                        <td>{t.date}</td>
                        <td className="right">
                            <Link className="button" to={`/tareas/editar/${t.id}`} onMouseEnter={showTooltip} onMouseLeave={hideTooltip} data-tooltip="Ver tarea"><span className="material-icons">visibility</span></Link>
                        </td>
                    </tr>)}
                </tbody>
            </table>) || <table className="list">
                <thead>
                    <tr>
                        <th><center>No hay tareas que cumplan con los filtros de búsqueda.</center></th>
                    </tr>
                </thead>
            </table>}
            <Pagination pages={pages} url="tareas" />
        </div>
    </section>
}
export default Task