import '../styles/dashboard.sass'
import { Link } from 'react-router-dom'
import pqrs from '../image/icon-pqrs.jpg'
import areas from '../image/icon-areas.jpg'
import { useContext, useMemo } from 'react'
import pedido from '../image/icon-pedido.jpg'
import tareas from '../image/icon-tareas.jpg'
import pedidos from '../image/icon-pedidos.jpg'
import cliente from '../image/icon-cliente.jpg'
import sperity from '../image/icon-sperity.jpg'
import usuarios from '../image/icon-usuarios.jpg'
import { AppContext } from '../context/AppContext'
import productos from '../image/icon-productos.jpg'
import postventa from '../image/icon-postventa.jpg'

const Dashboard = () => {
    const { user } = useContext(AppContext)
    
    return useMemo(() => {
        if(user?.typelogin === 'client')
            return <section className="dashboard">
                <div className="content">
                    <Link to="/pedidos/">
                        <img src={pedidos} alt="Listado de pedidos" />
                        Listado de Pedidos
                    </Link>
                    <Link to="/pedidos/crear/">
                        <img src={pedido} alt="Creación de pedido" />
                        Creación de Pedido
                    </Link>
                </div>
            </section>
        if(['0', '1'].includes(user?.type))
            return <section className="dashboard">
                <div className="content">
                    <Link to="/usuarios/">
                        <img src={usuarios} alt="Usuarios" />
                        Usuarios
                    </Link>
                    <Link to="/pedidos/">
                        <img src={pedidos} alt="Listado de pedidos" />
                        Listado de Pedidos
                    </Link>
                    <Link to="/pqrs/">
                        <img src={pqrs} alt="PQRS" />
                        PQRS
                    </Link>
                    <Link to="/tareas/">
                        <img src={tareas} alt="Listado de tareas" />
                        Listado de tareas
                    </Link>
                    <Link to="/tareas/crear/postventa/">
                        <img src={postventa} alt="Crear solicitud postventa" />
                        Crear Solicitud Postventa
                    </Link>
                    <Link to="/tareas/crear/sperity/">
                        <img src={sperity} alt="Crear Solicitud Sperity" />
                        Crear Solicitud Sperity
                    </Link>
                    <Link to="/clientes/">
                        <img src={cliente} alt="Clientes" />
                        Clientes
                    </Link>
                    <Link to="/productos/">
                        <img src={productos} alt="Productos" />
                        Productos
                    </Link>
                    <Link to="/configuracion/">
                        <img src={areas} alt="Configuración" />
                        Configuración
                    </Link>
                </div>
            </section>
        if(user?.type === '2')
            return <section className="dashboard">
                <div className="content">
                    <Link to="/pedidos/">
                        <img src={pedidos} alt="Listado de pedidos" />
                        Listado de Pedidos
                    </Link>
                    <Link to="/pedidos/crear/">
                        <img src={pedido} alt="Creación de pedido" />
                        Creación de Pedido
                    </Link>
                    <Link to="/pqrs/">
                        <img src={pqrs} alt="PQRS" />
                        PQRS
                    </Link>
                    <Link to="/tareas/">
                        <img src={tareas} alt="Listado de tareas" />
                        Listado de tareas
                    </Link>
                    <Link to="/tareas/crear/postventa/">
                        <img src={postventa} alt="Crear solicitud postventa" />
                        Crear Solicitud Postventa
                    </Link>
                    <Link to="/tareas/crear/sperity/">
                        <img src={sperity} alt="Crear Solicitud Sperity" />
                        Crear Solicitud Sperity
                    </Link>
                    <Link to="/clientes/">
                        <img src={cliente} alt="Clientes" />
                        Clientes
                    </Link>
                </div>
            </section>
        return null
    }, [user])
}
export default Dashboard