import { toast, Flip } from 'react-toastify'
import { business } from '../context/Variables'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const AddProduct = ({n, add, update, remove, product, single}) => {
    const [ post, setPost ] = useState(product)
    const { updateData } = useContext(AppContext)

    const setId = data => {
        setPost({...post, id: data.id})
        toast.success(data.updated, { theme: "colored", transition: Flip })
    }
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('product', 'update', formData, setId)
    }

    useEffect(() => update(post), [post])

    return <div className="add-block">
        <div>
            <h6>Producto {product?.id ? `"${product?.name}"` : '# '+n}</h6>
            {!single && <a className="add" href="#" onClick={add}><span className="material-icons">add_circle</span> Agregar otro producto</a>}
        </div>
        <a className="remove" href="#" onClick={() => remove(post?.n, post?.id)}>Eliminar producto<span className="material-icons">do_disturb_on</span></a>
        <form onSubmit={doUpdate}>
            <div className="field">
                <label htmlFor="business">Unidad de negocio</label>
                <select name="business" value={post?.business || ''} onChange={updateField} required>
                    <option value="">Seleccionar</option>
                    {business.map((c, k) => <option key={k} value={c.id}>{c.name}</option>)}
                </select>
            </div>
            <div className="field">
                <label htmlFor="code">Código</label>
                <input type="text" name="code" value={post?.code || ''} autoComplete="off" onChange={updateField} required />
            </div>
            <div className="field">
                <label htmlFor="name">Nombre de producto</label>
                <input type="text" name="name" value={post?.name || ''} autoComplete="off" onChange={updateField} required />
            </div>
            <div className="field">
                <label htmlFor="stock">Disponibilidad</label>
                <select name="stock" value={post?.stock || ''} onChange={updateField} required>
                    <option value="">Seleccionar</option>
                    <option value="1">Disponible</option>
                    <option value="0">No disponible</option>
                </select>
            </div>
            <div className="field left">
                <label className="w-100">&nbsp;</label>
                <button className="button" type="submit">Guardar</button>
            </div>
        </form>
    </div>
}
export default AddProduct