import AddState from './AddState'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import InputSearch from '../components/InputSearch'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { pqrs_subject, pqrs_impact } from '../context/Variables'

const Pqrs = () => {
    const factura = useRef(null)
    const navigate = useNavigate()
    const { action, id } = useParams()
    const [ states, setStates ] = useState([])
    const [ scrollTop, setScrollTop ] = useState(0)
    const [ post, setPost ] = useState({receipt: ''})
    const { user, main, updateData, apiURL } = useContext(AppContext)

    const updateField = (e) => {
        let _post = {...post}
        if(e.target.name == 'nit'){
            _post['name'] = e.target.data.name
            _post['client'] = e.target.data.id
            _post['email'] = e.target.data.email
            _post['phone'] = e.target.data.phone
            _post['wallet'] = e.target.data.wallet
            _post['status'] = e.target.data.status
        }
        _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('pqrs', 'update', formData, data => {
            toast.success(data.updated, { theme: "colored", transition: Flip })
            navigate(`/pqrs/editar/${data.id}/`)
        })
    }
    const updateState = (data) => {
        setScrollTop(main.current.scrollTop)
        setStates(() => {
            const _index = states.findIndex(p => p.n == data?.n)
            const _states = [...states]
            _states[_index] = data
            return _states
        })
    }
    const addState = () => setStates([...states, {n: states.length + 1, pqrs: id}])
    
    useEffect(() => {
        if(states.length == 0 && action == 'editar'){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}pqrs/get/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else{
                        setPost(data)
                        setStates(data.states.length > 0 && (data.states.at(-1).state != 2 && [...data.states, {n: data.states.length + 1, pqrs: id}] || [...data.states]) || [{n: 1, pqrs: id}])
                    }
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }else if(states.length == 0 && id)
            setStates([{n: 1, pqrs: id}])
        if(main.current.scrollTop == 0)
            main.current.scrollTop = scrollTop
    }, [scrollTop, id, states])

    return <section className="pqrs">
        <div className="content">
            <Link className="go-back" to="/pqrs/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>{action == 'editar' && (states?.at(-2)?.state == 2 && 'Información de la PQRS' || 'Editar PQRS') || 'Creación de PQRS'}</h1>
            <p>Diligencie el formulario con los datos de la PQRS.</p>
            <h5>Información del cliente</h5>
            <form className="parent-block" onSubmit={doUpdate}>
                <div className="field">
                    <label htmlFor="nit">NIT / CC</label>
                    <InputSearch type="number" name="nit" value={post?.nit || ''} placeholder="Buscar clientes..." onChange={updateField} required={true} readOnly={id} disabled={id} search="client/search/" />
                    <input type="hidden" name="client" value={post?.client || ''} required />
                </div>
                <div className="field">
                    <label>Razón social</label>
                    <input type="text" value={post?.name || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Correo electrónico</label>
                    <input type="text" value={post?.email || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Teléfono</label>
                    <input type="text" value={post?.phone || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label htmlFor="subject">Motivo</label>
                    <select name="subject" value={post?.subject || ''} onChange={updateField} required readOnly={id} disabled={id}>
                        <option value="">Seleccionar</option>
                        {pqrs_subject.map((s, k) => <option key={k} value={s.id}>{s.name}{s.area && ' ('+s.area+')' || ''}</option>)}
                    </select>
                </div>
                <div ref={factura} className={'field factura'+([10].some(s => s == +post?.subject) && ' show' || ' hide')}>
                    <label htmlFor="receipt">Factura</label>
                    <input type="text" name="receipt" value={post?.receipt || ''} autoComplete="off" onChange={updateField} required={[10].some(s => s == +post?.subject)} readOnly={id} disabled={id} />
                </div>
                <div className="field">
                    <label htmlFor="impact">Grado de impacto</label>
                    <select name="impact" value={post?.impact || ''} onChange={updateField} required readOnly={id} disabled={id}>
                        <option value="">Seleccionar</option>
                        {pqrs_impact.map((i, k) => <option key={k} value={i.id}>{i.name}</option>)}
                    </select>
                </div>
                <div className="field w-100">
                    <label htmlFor="message">Observaciones</label>
                    <textarea name="message" value={post?.message || ''} onChange={updateField} required readOnly={id} disabled={id}></textarea>
                </div>
                {!id && <div className="w-100 text-center">
                    <button type="submit" className="button">Enviar solicitud</button>
                </div> || null}
            </form>
            <div className={'parent-block'+(states?.at(-2)?.state == 2 && ' completed' || '')}>
                <h5>Cambios de estados y asignaciones</h5>
                {states.length > 0 && states?.map((s, i) => {
                    if(s.id || user?.type < 2)
                        return <AddState key={s.n} n={i+1} update={updateState} add={addState} state={s} length={states.length} />
                    return ''
                })}
            </div>
        </div>
    </section>
}
export default Pqrs