import '../styles/user.sass'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import InputSearch from '../components/InputSearch'
import { getImageData } from '../hook/GetImageData'
import { Link, useParams, useNavigate } from 'react-router-dom'
import { useState, useEffect, useContext, useRef } from 'react'
import { cities, postventa_subject } from '../context/Variables'

const Task = () => {
    const navigate = useNavigate()
    const imageButton = useRef(null)
    const { action, id } = useParams()
    const read = id && action == 'editar'
    const [ post, setPost ] = useState({})
    const { user, main, updateData, checkChannel, apiURL } = useContext(AppContext)

    const updateField = (e) => {
        let _post = {...post}
        if(e.target.name === 'image_file')
            getImageData(e.target.files[0], 'imagen', post, setPost)
        else if(e.target.name == 'nit'){
            _post['name'] = e.target.data.name
            _post['client'] = e.target.data.id
            _post['email'] = e.target.data.email
            _post['phone'] = e.target.data.phone
            _post['wallet'] = e.target.data.wallet
            _post['status'] = e.target.data.status
            _post['channel'] = e.target.data.channel
        }else if(e.target.name == 'code'){
            _post['point'] = e.target.data.id
            _post['city'] = e.target.data.city
            _post['address'] = e.target.data.address
        }else if(e.target.name == 'agent')
            _post['agent_id'] = e.target.data.id
        _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('task', 'update', formData, data => {
            toast.success(data.updated, { theme: "colored", transition: Flip })
            navigate(`/tareas/editar/${data.id}/`)
        })
    }

    useEffect(() => {
        main.current.scrollTop = 0
        if(action == 'editar' && id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}task/get/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setPost(data)
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }
    }, [id, action])

    return <section className="order">
        <div className="content">
            <Link className="go-back" to={action == 'editar' && `/tareas/${id == 'postventa' && 'postventa' || 'sperity'}/` || '/'}><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>{action == 'editar' ? 'Editar '+((post?.testo && 'solicitud Sperity') || 'solicitud postventa') : 'Creación de '+(id == 'postventa' && 'solicitud postventa' || 'solicitud Sperity')}</h1>
            <p>Diligencie el formulario con los datos de la tarea.</p>
            <form onSubmit={doUpdate} className="parent-block bottom">
                <h5 className="w-100">Información del cliente</h5>
                {(user?.type < 2 && <div className="field w-100">
                    <label htmlFor="agent">Comercial</label>
                    <InputSearch type="text" name="agent" value={post?.agent || ''} placeholder="Buscar comerciales..." onChange={updateField} required={true} readOnly={read} disabled={read} search="user/search/" />
                    <input type="hidden" name="agent_id" value={post?.agent_id || ''} required />
                </div>) || null}
                <div className="field">
                    <label htmlFor="nit">NIT / CC</label>
                    <InputSearch type="number" name="nit" value={post?.nit || ''} placeholder="Buscar clientes..." onChange={updateField} required={true} readOnly={read} disabled={read} search="client/search/" />
                    <input type="hidden" name="client" value={post?.client || ''} required />
                </div>
                <div className="field">
                    <label>Razón social</label>
                    <input type="text" value={post?.name || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Subcanal</label>
                    <input type="text" value={post?.channel && checkChannel(post?.channel)?.name || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Correo electrónico</label>
                    <input type="text" value={post?.email || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Teléfono</label>
                    <input type="text" value={post?.phone || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label htmlFor="code">Código de sucursal</label>
                    <InputSearch type="number" name="code" value={post?.code || ''} placeholder="Buscar sucursales..." onChange={updateField} required={true} readOnly={read} disabled={read || !post?.name} search={`point/search/${post?.client}/`} />
                    <input type="hidden" name="point" value={post?.point || ''} required />
                </div>
                <div className="field">
                    <label>Ciudad</label>
                    <input type="text" value={post?.city && cities.find(c => post.city == c.id).name || ''} placeholder="Debe ingresar el código de la sucursal" readOnly disabled />
                </div>
                <div className="field">
                    <label>Dirección de sucursal</label>
                    <input type="text" value={post?.address || ''} placeholder="Debe ingresar el código de la sucursal" readOnly disabled />
                </div>
                <div className="field">
                    <label htmlFor="date">Fecha</label>
                    {read && 
                        <input type="text" value={post?.date || ''} readOnly disabled /> ||
                        <input type="datetime-local" name="date" value={post?.date || ''} autoComplete="off" onChange={updateField} required />
                    }
                </div>
                <div className={'field'+(((action == 'editar' && !post.testo) || id == 'postventa') && ' w-100' || '')}>
                    <label htmlFor="subject">Motivo</label>
                    {read && 
                        <input type="text" value={post?.subject || ''} readOnly disabled /> ||
                        <select name="subject" value={post?.subject || ''} onChange={updateField} required>
                            <option value="">Seleccionar</option>
                            {postventa_subject.map((s, k) => <option key={k} value={s.id}>{s.name}{s.area && ' ('+s.area+')' || ''}</option>)}
                        </select>
                    }
                </div>
                {(((action == 'editar' && post.testo) || id == 'sperity') && <>
                    <div className="field left image">
                        <label className="w-100" htmlFor="image_file">Imagen adjunta</label>
                        {!read && <>
                            <input type="hidden" name="imagen" value={post?.imagen || ''} />
                            <input ref={imageButton} className="hide" type="file" name="image_file" accept=".png, .jpg, .jpeg" onChange={updateField} />
                            <button className={'button gray'+(read && ' hide' || '')} onClick={(e) => { e.preventDefault(); imageButton.current.click(); }}>{post?.imagen && 'Cargada' || 'Seleccionar'}</button>
                        </> || ''}
                        <img className={(post?.imagen && 'profile') || 'hide'} src={post?.imagen || ''} alt="Imagen adjunta" />
                    </div>
                    <h5 className="w-100">Equipos descalibrados</h5>
                    <div className="field">
                        <label htmlFor="testo">Temperatura del testo (°C)</label>
                        {read && 
                            <input type="text" value={post?.testo || ''} readOnly disabled /> ||
                            <select name="testo" value={post?.testo || ''} onChange={updateField} required readOnly={read} disabled={read}>
                                <option value="">Seleccionar</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field left">
                        <label htmlFor="termocupla">Temperatura de la termocupla (°C)</label>
                        {read && 
                            <input type="text" value={post?.termocupla || ''} readOnly disabled /> ||
                            <select name="termocupla" value={post?.termocupla || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Desconocimiento de Ciclo de freidos optimos</h5>
                    <div className="field">
                        <label htmlFor="freidoras">Cuantas freidoras tiene (und)</label>
                        {read && 
                            <input type="text" value={post?.freidoras || ''} readOnly disabled /> ||
                            <select name="freidoras" value={post?.freidoras || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="alimentos">Tipo de alimentos Frita</label>
                        {read && 
                            <input type="text" value={post?.alimentos || ''} readOnly disabled /> ||
                            <select name="alimentos" value={post?.alimentos || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Almidones (Papas a la francesa, Yuca, Patacon)">Almidones (Papas a la francesa, Yuca, Patacon)</option>
                                <option value="Apanados (Chuleta, Alitas, Nugets, pollo)">Apanados (Chuleta, Alitas, Nugets, pollo)</option>
                                <option value="Proteinas (Costilla, Chorizo, Maduro)">Proteinas (Costilla, Chorizo, Maduro)</option>
                                <option value="Pescados (Mojarra)">Pescados (Mojarra)</option>
                                <option value="Chicharrón">Chicharrón</option>
                                <option value="Empanadas, buñuelos, arepas de huevo">Empanadas, buñuelos, arepas de huevo</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="ciclo_feido">Cual de estos ciclos de freido aplica</label>
                        {read && 
                            <input type="text" value={post?.ciclo_feido || ''} readOnly disabled /> ||
                            <select name="ciclo_feido" value={post?.ciclo_feido || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="No aplica ningun ciclo">No aplica ningun ciclo</option>
                                <option value="Aceite de los almidones a apanados">Aceite de los almidones a apanados</option>
                                <option value="Aceite de apanados a proteinas o pescados">Aceite de apanados a proteinas o pescados</option>
                                <option value="Aceite de Proteinas a Chicharron">Aceite de Proteinas a Chicharron</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Filtracion de aceite</h5>
                    <div className="field">
                        <label htmlFor="frecuencia_filtrado">Con que frecuencia realiza el filtrado?</label>
                        {read && 
                            <input type="text" value={post?.frecuencia_filtrado || ''} readOnly disabled /> ||
                            <select name="frecuencia_filtrado" value={post?.frecuencia_filtrado || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="No realiza filtrado	Diario">No realiza filtrado	Diario</option>
                                <option value="Cada 2 días">Cada 2 días</option>
                                <option value="Cada 3 días">Cada 3 días</option>
                                <option value="Cada 4 días">Cada 4 días</option>
                                <option value="1 ves por semana">1 ves por semana</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="herramientas">Con cual de estas herramientas lo hace?</label>
                        {read && 
                            <input type="text" value={post?.herramientas || ''} readOnly disabled /> ||
                            <select name="herramientas" value={post?.herramientas || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Filtro Tela">Filtro Tela</option>
                                <option value="Magnesol">Magnesol</option>
                                <option value="Maquina de filtrado automatico">Maquina de filtrado automatico</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="compuestos">Compuestos Polares (%Tpm)</label>
                        {read && 
                            <input type="text" value={post?.compuestos || ''} readOnly disabled /> ||
                            <select name="compuestos" value={post?.compuestos || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="6">6</option>
                                <option value="7">7</option>
                                <option value="8">8</option>
                                <option value="9">9</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                                <option value="24">24</option>
                                <option value="25">25</option>
                                <option value="26">26</option>
                                <option value="27">27</option>
                                <option value="28">28</option>
                                <option value="29">29</option>
                                <option value="30">30</option>
                                <option value="31">31</option>
                                <option value="32">32</option>
                                <option value="33">33</option>
                                <option value="34">34</option>
                                <option value="35">35</option>
                                <option value="36">36</option>
                                <option value="37">37</option>
                                <option value="38">38</option>
                                <option value="39">39</option>
                                <option value="40">40</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Limpieza de equipos</h5>
                    <div className="field">
                        <label htmlFor="freidora_contaminantes">La freidora tiene presencia de materiales contaminantes?</label>
                        {read && 
                            <input type="text" value={post?.freidora_contaminantes || ''} readOnly disabled /> ||
                            <select name="freidora_contaminantes" value={post?.freidora_contaminantes || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="campana_contaminantes">La campana tiene presencia de materiales contaminantes?</label>
                        {read && 
                            <input type="text" value={post?.campana_contaminantes || ''} readOnly disabled /> ||
                            <select name="campana_contaminantes" value={post?.campana_contaminantes || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="espumadera_contaminantes">La espumadera tiene presencia de materiales contaminantes?</label>
                        {read && 
                            <input type="text" value={post?.espumadera_contaminantes || ''} readOnly disabled /> ||
                            <select name="espumadera_contaminantes" value={post?.espumadera_contaminantes || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="canasta_contaminantes">la canasta de alimentos tiene presencia de materiales contaminantes?</label>
                        {read && 
                            <input type="text" value={post?.canasta_contaminantes || ''} readOnly disabled /> ||
                            <select name="canasta_contaminantes" value={post?.canasta_contaminantes || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Descartes de aceite anticipado</h5>
                    <div className="field">
                        <label htmlFor="cambio_aceite">Como determina usted el cambio de aceite?</label>
                        {read && 
                            <input type="text" value={post?.cambio_aceite || ''} readOnly disabled /> ||
                            <select name="cambio_aceite" value={post?.cambio_aceite || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Ojo">Ojo</option>
                                <option value="Caracteristacas Organolepticas del alimento">Caracteristacas Organolepticas del alimento</option>
                                <option value="Tiras acidez">Tiras acidez</option>
                                <option value="Testo">Testo</option>
                                <option value="Frecuencia de cambio estandarizada">Frecuencia de cambio estandarizada</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="dias_uso_papas">Cuantos dias de uso lleva el aceite en la freidora de papas y/o empanadas?</label>
                        {read && 
                            <input type="text" value={post?.dias_uso_papas || ''} readOnly disabled /> ||
                            <select name="dias_uso_papas" value={post?.dias_uso_papas || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Entre 1 y 3 dias">Entre 1 y 3 dias</option>
                                <option value="Entre 4 y 7 dias">Entre 4 y 7 dias</option>
                                <option value="Entre 8 y 10 dias">Entre 8 y 10 dias</option>
                                <option value="Entre 11 y 14 dias">Entre 11 y 14 dias</option>
                                <option value="Entre 15 y 17 dias">Entre 15 y 17 dias</option>
                                <option value="Entre 18 y 23 dias">Entre 18 y 23 dias</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="dias_uso_apanados">Cuantos dias de uso lleva el aceite en la freidora de apanados o pescados?</label>
                        {read && 
                            <input type="text" value={post?.dias_uso_apanados || ''} readOnly disabled /> ||
                            <select name="dias_uso_apanados" value={post?.dias_uso_apanados || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Entre 1 y 3 dias">Entre 1 y 3 dias</option>
                                <option value="Entre 4 y 7 dias">Entre 4 y 7 dias</option>
                                <option value="Entre 8 y 10 dias">Entre 8 y 10 dias</option>
                                <option value="Entre 11 y 14 dias">Entre 11 y 14 dias</option>
                                <option value="Entre 15 y 17 dias">Entre 15 y 17 dias</option>
                                <option value="Entre 18 y 23 dias">Entre 18 y 23 dias</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="dias_uso_patacones">Cuantos dias de uso lleva el aceite en la freidora de Patacones?</label>
                        {read && 
                            <input type="text" value={post?.dias_uso_patacones || ''} readOnly disabled /> ||
                            <select name="dias_uso_patacones" value={post?.dias_uso_patacones || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Entre 1 y 3 dias">Entre 1 y 3 dias</option>
                                <option value="Entre 4 y 7 dias">Entre 4 y 7 dias</option>
                                <option value="Entre 8 y 10 dias">Entre 8 y 10 dias</option>
                                <option value="Entre 11 y 14 dias">Entre 11 y 14 dias</option>
                                <option value="Entre 15 y 17 dias">Entre 15 y 17 dias</option>
                                <option value="Entre 18 y 23 dias">Entre 18 y 23 dias</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Validacion de condiciones iniciales de alimentos</h5>
                    <div className="field">
                        <label htmlFor="valida_temperatura">Usted valida la temperatura de la papa a la francesa en congelamiento, previo a su fritura?</label>
                        {read && 
                            <input type="text" value={post?.valida_temperatura || ''} readOnly disabled /> ||
                            <select name="valida_temperatura" value={post?.valida_temperatura || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        }
                    </div>
                    <div className="field left">
                        <label htmlFor="agrega_desde">Usted agrega papas a la freidora desde?</label>
                        {read && 
                            <input type="text" value={post?.agrega_desde || ''} readOnly disabled /> ||
                            <select name="agrega_desde" value={post?.agrega_desde || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Congelador">Congelador</option>
                                <option value="Mesa a T Ambiente">Mesa a T Ambiente</option>
                                <option value="Nevera">Nevera</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Falta de Control de inventarios</h5>
                    <div className="field">
                        <label htmlFor="control_inventarios">Con que herramientas de control de inventarios cuenta?</label>
                        {read && 
                            <input type="text" value={post?.control_inventarios || ''} readOnly disabled /> ||
                            <select name="control_inventarios" value={post?.control_inventarios || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Sistema de inventarios">Sistema de inventarios</option>
                                <option value="Formato Excel">Formato Excel</option>
                                <option value="Sensores">Sensores</option>
                                <option value="Ninguno">Ninguno</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="consumo_semanal">Cual es su consumo actual en litros semanal?</label>
                        {read && 
                            <input type="text" value={post?.consumo_semanal || ''} readOnly disabled /> ||
                            <select name="consumo_semanal" value={post?.consumo_semanal || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="20 litros">20 litros</option>
                                <option value="40 litros">40 litros</option>
                                <option value="50 litros">50 litros</option>
                                <option value="70 litros">70 litros</option>
                                <option value="80 litros">80 litros</option>
                                <option value="90 litros">90 litros</option>
                                <option value="100 litros">100 litros</option>
                                <option value="110 litros">110 litros</option>
                                <option value="120 litros ">120 litros </option>
                                <option value="140 litros">140 litros</option>
                                <option value="160 litros">160 litros</option>
                                <option value="170 litros">170 litros</option>
                                <option value="200 litros">200 litros</option>
                                <option value="210 litros">210 litros</option>
                                <option value="220 litros">220 litros</option>
                                <option value="230 litros">230 litros</option>
                                <option value="240 litros">240 litros</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="consumo_mensual">Cual es su consumo actual en litros mensual?</label>
                        {read && 
                            <input type="text" value={post?.consumo_mensual || ''} readOnly disabled /> ||
                            <select name="consumo_mensual" value={post?.consumo_mensual || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="80 litros">80 litros</option>
                                <option value="160 litros">160 litros</option>
                                <option value="200 litros">200 litros</option>
                                <option value="280 litros">280 litros</option>
                                <option value="320 litros">320 litros</option>
                                <option value="560 litros">560 litros</option>
                                <option value="400 litros">400 litros</option>
                                <option value="440 litros">440 litros</option>
                                <option value="480 litros">480 litros</option>
                                <option value="560 litros">560 litros</option>
                                <option value="640 litros">640 litros</option>
                                <option value="680 litros">680 litros</option>
                                <option value="800 litros">800 litros</option>
                                <option value="840 litros">840 litros</option>
                                <option value="880 litros">880 litros</option>
                                <option value="920 litros">920 litros</option>
                                <option value="960 litros">960 litros</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Falta de Controles de Temperatura adecuados</h5>
                    <div className="field">
                        <label htmlFor="t_papas">A cual de estas T usted frita papas a la francesa? T en (°C)</label>
                        {read && 
                            <input type="text" value={post?.t_papas || ''} readOnly disabled /> ||
                            <select name="t_papas" value={post?.t_papas || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="140">140</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="160">160</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="t_apanados">A cual de estas T usted frita Productos apanados? T en (°C)</label>
                        {read && 
                            <input type="text" value={post?.t_apanados || ''} readOnly disabled /> ||
                            <select name="t_apanados" value={post?.t_apanados || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="140">140</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="160">160</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="t_pescados">A cual de estas T usted frita Pescados? T en (°C)</label>
                        {read && 
                            <input type="text" value={post?.t_pescados || ''} readOnly disabled /> ||
                            <select name="t_pescados" value={post?.t_pescados || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="140">140</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="160">160</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="t_patacones">A cual de estas T usted frita patacones? T en (°C)</label>
                        {read && 
                            <input type="text" value={post?.t_patacones || ''} readOnly disabled /> ||
                            <select name="t_patacones" value={post?.t_patacones || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="140">140</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="160">160</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="t_chicharrones">A cual de estas T usted frita Chicharrones? T en (°C)</label>
                        {read && 
                            <input type="text" value={post?.t_chicharrones || ''} readOnly disabled /> ||
                            <select name="t_chicharrones" value={post?.t_chicharrones || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="140">140</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="160">160</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="t_lumpias">A cual de estas T usted frita Lumpias? T en (°C)</label>
                        {read && 
                            <input type="text" value={post?.t_lumpias || ''} readOnly disabled /> ||
                            <select name="t_lumpias" value={post?.t_lumpias || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="140">140</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="160">160</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field">
                        <label htmlFor="t_empanadas">A cual de estas T usted frita empanadas? T en (°C)</label>
                        {read && 
                            <input type="text" value={post?.t_empanadas || ''} readOnly disabled /> ||
                            <select name="t_empanadas" value={post?.t_empanadas || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="140">140</option>
                                <option value="150">150</option>
                                <option value="155">155</option>
                                <option value="160">160</option>
                                <option value="165">165</option>
                                <option value="170">170</option>
                                <option value="175">175</option>
                                <option value="180">180</option>
                                <option value="185">185</option>
                                <option value="190">190</option>
                                <option value="195">195</option>
                                <option value="200">200</option>
                            </select>
                        }
                    </div>
                    <div className="field left">
                        <label htmlFor="t_capacitacion">El personal recibe capacitacion de T ideales por alimento?</label>
                        {read && 
                            <input type="text" value={post?.t_capacitacion || ''} readOnly disabled /> ||
                            <select name="t_capacitacion" value={post?.t_capacitacion || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">Entrada de oxigeno a la freidora</h5>
                    <div className="field">
                        <label htmlFor="tapa_aceite">Tapa usted el aceite al finalizar la operacion?</label>
                        {read && 
                            <input type="text" value={post?.tapa_aceite || ''} readOnly disabled /> ||
                            <select name="tapa_aceite" value={post?.tapa_aceite || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="Si">Si</option>
                                <option value="No">No</option>
                            </select>
                        }
                    </div>
                    <h5 className="w-100">No nivelan la cap del aceite adecuadamente </h5>
                    <div className="field">
                        <label htmlFor="frecuencia_nivela">Con que frecuencia usted nivela el aceite?</label>
                        {read && 
                            <input type="text" value={post?.frecuencia_nivela || ''} readOnly disabled /> ||
                            <select name="frecuencia_nivela" value={post?.frecuencia_nivela || ''} onChange={updateField} required>
                                <option value="">Seleccionar</option>
                                <option value="No realiza nivelacion">No realiza nivelacion</option>
                                <option value="Diario">Diario</option>
                                <option value="Cada 2 días">Cada 2 días</option>
                                <option value="Cada 3 días">Cada 3 días</option>
                                <option value="Cada 4 días">Cada 4 días</option>
                                <option value="1 ves por semana">1 ves por semana</option>
                            </select>
                        }
                    </div>
                </>) || ''}
                <div className="field w-100">
                    <label htmlFor="message">Comentarios</label>
                    <textarea name="message" value={post?.message || ''} onChange={updateField} required readOnly={read} disabled={read}></textarea>
                </div>
                {!read && <button type="submit" className="button">{read && 'Actualizar' || 'Guardar'}</button> || ''}
            </form>
        </div>
    </section>
}
export default Task