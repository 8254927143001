import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const AddFreeDays = ({update, remove, freeday}) => {
    const [ post, setPost ] = useState(freeday)
    const { updateData } = useContext(AppContext)

    const setId = (data) => {
        setPost({...post, id: data.id})
        toast.success(data.updated, { theme: "colored", transition: Flip })
    }
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('freeday', 'update', formData, setId)
    }

    useEffect(() => update(post), [post])

    return <tr>
        <td><div className="field"><input type="text" name="description" value={post?.description || ''} placeholder="Describa los días no laborales" autoComplete="off" onChange={updateField} required /></div></td>
        <td><div className="field"><select name="cond" value={post?.cond || ''} onChange={updateField} required>
            <option value=''>Seleccionar</option>
            <option value='0'>Todos los domingo</option>
            <option value='1'>Todos los lunes</option>
            <option value='2'>Todos los martes</option>
            <option value='3'>Todos los miércoles</option>
            <option value='4'>Todos los jueves</option>
            <option value='5'>Todos los viernes</option>
            <option value='6'>Todos los sábado</option>
            <option value='7'>Día o rango específico</option>
        </select></div></td>
        <td><div className="field"><input type="date" name="from" value={post?.from || ''} autoComplete="off" onChange={updateField} required={post?.cond == 8} disabled={post?.cond != 7} /></div></td>
        <td><div className="field"><input type="date" name="to" value={post?.to || ''} autoComplete="off" onChange={updateField} required={post?.cond == 7} disabled={post?.cond != 7} /></div></td>
        <td className="right"><a className="button" onClick={doUpdate}>Guardar</a><a className="button" onClick={() => remove(post?.n, post?.id)}><span className="material-icons">close</span></a></td>
    </tr>
}
export default AddFreeDays