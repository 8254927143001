import '../styles/user.sass'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useContext, useRef } from 'react'

const Uploads = () => {
    const fileButton = useRef(null)
    const [ post, setPost ] = useState({})
    const { updateData } = useContext(AppContext)

    const doDropdown = e => {
        const isActive = e.target.parentElement.classList.contains('open')
        document.querySelectorAll('.dropdown').forEach((el) => el.classList.remove('open'));
        if(!isActive)
            e.target.parentElement.classList.add('open')
    }
    const updateField = (e) => setPost({...post, [e.target.name]: e.target.value})
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        formData.append('type', post['type'])
        formData.append('file', fileButton.current.files[0])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('upload', post['type'], formData, data => {
            if(data.error)
                toast.error(data.error, { theme: "colored", transition: Flip })
            else{
                setPost([])
                toast.success(data['updated'], { theme: "colored", transition: Flip })
            }
        })
    }

    return <div className="content py-0 dropdown">
        <a className="dropdown-area" onClick={doDropdown}></a>
        <h1>Carga de información</h1>
        <p>Seleccione el tipo de datos que desea subir masivamente a la base de datos, seleccione el archivo CSV a subir y cargue la información.</p>
        <h5>Información a cargar</h5>
        <form className="parent-block" onSubmit={doUpdate}>
            <div className="field">
                <label htmlFor="type">Tipo de actualización</label>
                <select name="type" value={post?.type || ''} onChange={updateField} required>
                    <option value="">Seleccionar</option>
                    <option value="clients">Clientes</option>
                    <option value="wallet">Cartera</option>
                </select>
            </div>
            <div className="field image">
                <label className="w-100" htmlFor="csv_file">Documento CSV</label>
                <button className="button gray" onClick={(e) => { e.preventDefault(); fileButton.current.click(); }}>{post?.file && 'Cargado' || 'Seleccionar'}</button>
                <span className="csv-label">{post?.file && post.file.split('\\').at(-1) || ''}</span><br />
                <input ref={fileButton} className="invisible" type="file" name="file" accept=".csv" onChange={updateField} required />
            </div>
            <div className="field">
                <label className="w-100">&nbsp;</label>
                <button className="button" type="submit">Cargar</button>
            </div>
        </form>
    </div>
}
export default Uploads