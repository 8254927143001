import { channels } from './Variables'
import { toast, Flip } from 'react-toastify'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useState, useEffect, createContext, useCallback, useRef } from 'react'

export const AppContext = createContext()
const AppProvider = ({ children }) => {
    const main = useRef(null)
    const { token } = useParams()
    const location = useLocation()
    const navigate = useNavigate()
    const { Provider } = AppContext
    const [ user, setUser ] = useState(null)
    const [ loading, setLoading ] = useState(null)
    const apiURL = 'https://plataforma.incomer.com.co/api/'
    const tooltipContainer = document.getElementById('tooltip')
    //const apiURL = 'https://boldsample.com/proyectos/incomer/api/'

    const load = useCallback((loading) => setLoading(loading), [])
    const updateData = useCallback((table, action, formData = false, callback = false) => {
        if(formData){
            load(true)
            fetch(`${apiURL}${table}/${action}/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(data.error){
                        toast.error(data.error, { theme: "colored", transition: Flip })
                        if(action == 'login'){
                            localStorage.setItem('userToken', '')
                            localStorage.clear()
                            navigate('/iniciar-sesion/')
                        }
                    }else if(data.updated){
                        if(action == 'login'){
                            localStorage.setItem('userToken', data.token)
                            setUser(data.user)
                        }else if(callback)
                            callback(data)
                        else
                            toast.success(data.updated, { theme: "colored", transition: Flip })
                    }
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }else if(action == 'logout'){
            localStorage.setItem('userToken', '')
            localStorage.clear()
            return setUser(null)
        }
    }, [])
    const showTooltip = e => {
        tooltipContainer.style.display = 'block'
        tooltipContainer.style.setProperty("--tooltip-top", (e.target.getBoundingClientRect().y - 40)+'px')
        tooltipContainer.style.setProperty("--tooltip-left", (e.target.getBoundingClientRect().x - 48)+'px')
        tooltipContainer.querySelector('.text').innerHTML = e.target.getAttribute('data-tooltip')
    }
    const hideTooltip = () => {
        if(tooltipContainer)
            tooltipContainer.style.display = 'none'
    }
    const checkChannel = v => {
        const _value = +v
        let _return = false
        channels.find(c => {
            if(c.id && c.id === _value && !_return) _return = c
            else if(!c.id && !_return)
                _return = c.subchannels.find(s => s.id === _value)
        })
        return _return
    }
    
    useEffect(() => {
        const checkToken = document.location.pathname.split('/')
        if(checkToken.at(-3) && checkToken.at(-3) == 'perfil' && checkToken.at(-2) != ''){
            localStorage.setItem('userToken', checkToken.at(-2))
            navigate('/perfil/')
        }
        if(!user && !localStorage.getItem('userToken') && location.pathname !== '/iniciar-sesion/')
            navigate('/iniciar-sesion/')
        else if(user && localStorage.getItem('userToken') && location.pathname === '/iniciar-sesion/')
            navigate('/')
        else if(!user && localStorage.getItem('userToken')){
            let formData = new FormData()
            formData.append('token', localStorage.getItem('userToken'))
            updateData('user', 'login', formData)
        }
    }, [user])
    
    const appData = { main, user, loading, load, updateData, showTooltip, hideTooltip, checkChannel, apiURL }
    return <Provider value={appData}>
        <main ref={main}>{children}</main>
    </Provider>
}
export default AppProvider