import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import InputSearch from '../components/InputSearch'
import { useState, useEffect, useContext } from 'react'
import { postventa_subject } from '../context/Variables'
import { Link, useParams, useNavigate } from 'react-router-dom'

const Postventa = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const [post, setPost] = useState({})
    const { updateData, apiURL } = useContext(AppContext)

    const updateField = e => {
        let _post = {...post}
        if(e.target.name == 'nit'){
            _post['name'] = e.target.data.name
            _post['client'] = e.target.data.id
            _post['email'] = e.target.data.email
            _post['phone'] = e.target.data.phone
            _post['wallet'] = e.target.data.wallet
            _post['status'] = e.target.data.status
        }
        _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = e => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('postventa', 'update', formData, data => {
            toast.success(data.updated, { theme: "colored", transition: Flip })
            navigate(`/postventa/${data.id}/`)
        })
    }

    useEffect(() => {
        if(id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}postventa/get/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setPost(data)
                })
                .catch((e) => { toast.error('No se pudo establecer conexión con el servidor.', { theme: "colored", transition: Flip }) })
        }
    }, [id])

    return <section className="postventa">
        <div className="content">
            <Link className="go-back" to="/"><span className="material-icons">arrow_back</span> Regresar</Link>
            <h1>Solicitud Postventa</h1>
            <p>Diligencie el formulario con los datos de la solicitud.</p>
            <h5>Información del cliente</h5>
            <form className="parent-block" onSubmit={doUpdate}>
                <div className="field">
                    <label htmlFor="nit">NIT / CC</label>
                    <InputSearch type="number" name="nit" value={post?.nit || ''} placeholder="Buscar clientes..." onChange={updateField} required={true} readOnly={id} disabled={id} search="client/search/" />
                    <input type="hidden" name="client" value={post?.client || ''} required />
                </div>
                <div className="field">
                    <label>Razón social</label>
                    <input type="text" value={post?.name || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Correo electrónico</label>
                    <input type="text" value={post?.email || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field">
                    <label>Teléfono</label>
                    <input type="text" value={post?.phone || ''} placeholder="Debe ingresar el NIT del cliente" readOnly disabled />
                </div>
                <div className="field left">
                    <label htmlFor="date">Fecha de visita</label>
                    <input type="date" name="date" value={post?.date || ''} autoComplete="off" onChange={updateField} required readOnly={id} disabled={id} />
                </div>
                <div className="field">
                    <label htmlFor="subject">Motivo</label>
                    <select name="subject" value={post?.subject || ''} onChange={updateField} required readOnly={id} disabled={id}>
                        <option value="">Seleccionar</option>
                        {postventa_subject.map((s, k) => <option key={k} value={s.id}>{s.name}{s.area && ' ('+s.area+')' || ''}</option>)}
                    </select>
                </div>
                <div className="field w-100">
                    <label htmlFor="message">Observaciones</label>
                    <textarea name="message" value={post?.message || ''} onChange={updateField} required readOnly={id} disabled={id}></textarea>
                </div>
                {!id && <div className="w-100 text-center">
                    <button type="submit" className="button">Enviar solicitud</button>
                </div> || null}
            </form>
        </div>
    </section>
}
export default Postventa