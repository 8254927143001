import { Link, useParams } from 'react-router-dom'

const Pagination = ({ pages = 1, url='' }) => {
    const { page = 1 } = useParams()

    return (pages > 1 && <div className="pagination">
        <Link className={page <= 1 && 'inactive' || ''} to={`/${url}/page/${+page - 1}/`}><em className="material-icons">arrow_back</em></Link>
        {page > 2 && pages > 3 && <Link to={`/${url}/page/1/`}>1</Link> || null}
        {page > 3 && page > pages - 1 && +page - 3 > 0 && <Link to={`/${url}/page/${+page - 3}/`}>{+page - 3}</Link> || null}
        {page > 2 && page > pages - 2 && +page - 2 > 0 && <Link to={`/${url}/page/${+page - 2}/`}>{+page - 2}</Link> || null}
        {page > 1 && +page - 1 > 0 && <Link to={`/${url}/page/${+page - 1}/`}>{+page - 1}</Link> || null}
        <span>{page}</span>
        {page < pages - 1 && <Link to={`/${url}/page/${+page + 1}/`}>{+page + 1}</Link> || null}
        {page < pages - 2 && page < 3 && <Link to={`/${url}/page/${+page + 2}/`}>{+page + 2}</Link> || null}
        {page < pages - 3 && page < 2 && <Link to={`/${url}/page/${+page + 3}/`}>{+page + 3}</Link> || null}
        {page < pages && <Link to={`/${url}/page/${pages}/`}>{pages}</Link> || null}
        <Link className={page >= pages && 'inactive' || ''} to={`/${url}/page/${+page + 1}/`}><em className="material-icons">arrow_forward</em></Link>
</div> || null)
}
export default Pagination