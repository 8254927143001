import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import InputSearch from '../components/InputSearch'
import { useState, useEffect, useContext, useRef } from 'react'

const AddRequest = ({n, add, update, remove, validate, request, length = 0, readOnly}) => {
    const form = useRef(null)
    const formButton = useRef(null)
    const [ post, setPost ] = useState(request)
    const { updateData } = useContext(AppContext)

    const setId = (data) => setPost({...post, id: data.id})
    const updateField = (e) => {
        let _post = {...post}
        if(e.target.name == 'code'){
            _post['name'] = e.target.data.name
            _post['product'] = e.target.data.id
            _post['business'] = e.target.data.business
        }
        _post[e.target.name] = e.target.value
        setPost(_post)
    }
    const doUpdate = (e) => {
        e.preventDefault()
        let formData = new FormData()
        for(let key in post)
            formData.append(key, post[key])
        formData.append('token', localStorage.getItem('userToken'))
        updateData('request', 'update', formData, setId)
    }

    useEffect(() => {
        if(validate){
            if(validate.send)
                formButton.current.click()
            else if(form.current.reportValidity())
                validate.validated(true)
            else
                validate.validated(false)
        }else
            update(post)
    }, [post, validate])

    return <div className={'add-block'+(n == length && ' last' || '')}>
        <div>
            <h6>Solicitud # {n}</h6>
            {!readOnly && <a className="add" href="#" onClick={add}><span className="material-icons">add_circle</span> Agregar otra solicitud</a> || ''}
        </div>
        {!readOnly && <a className="remove" href="#" onClick={() => remove(post?.n, post?.id)}>Eliminar solicitud<span className="material-icons">do_disturb_on</span></a> || ''}
        <form ref={form} onSubmit={doUpdate}>
            <div className="field">
                <label htmlFor="code">Código</label>
                <InputSearch className={(!post?.code || !post?.name) && 'required' || ''} type="number" name="code" value={post?.code || ''} placeholder="Buscar productos..." onChange={updateField} required={true} search="product/search/" readOnly={readOnly} disabled={readOnly} />
            </div>
            <div className="field left">
                <label>Producto</label>
                <input type="text" value={post?.name || ''} placeholder="Ingresa código y selecciona producto" readOnly disabled />
                <input className="hidden" type="text" name="product" value={post?.product || ''} onChange={e => false} required />
            </div>
            <div className="field left">
                <label>Unidad de negocio</label>
                <input type="text" value={post?.business || ''} placeholder="Ingresa código y selecciona producto" readOnly disabled />
            </div>
            <div className="field">
                <label htmlFor="quantity">Cantidad</label>
                <input className={!post?.quantity && 'required' || ''} type="number" name="quantity" value={post?.quantity || ''} autoComplete="off" onChange={updateField} required readOnly={readOnly} disabled={readOnly} />
            </div>
            <button ref={formButton} className="hide" type="submit"></button>
        </form>
    </div>
}
export default AddRequest