import '../styles/user.sass'
import AddArea from '../action/AddArea'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const Area = () => {
    const [ areas, setAreas ] = useState(null)
    const [ scrollTop, setScrollTop ] = useState(0)
    const { main, load, updateData, apiURL } = useContext(AppContext)

    const doDropdown = e => {
        const isActive = e.target.parentElement.classList.contains('open')
        document.querySelectorAll('.dropdown').forEach((el) => el.classList.remove('open'));
        if(!isActive)
            e.target.parentElement.classList.add('open')
    }
    const addArea = () => {
        setScrollTop(main.current.scrollTop)
        setAreas([...areas, {n: areas.at(-1).n + 1}])
    }
    const updateArea = (data) => {
        setScrollTop(main.current.scrollTop)
        setAreas(() => {
            const _index = areas.findIndex(p => p.n == data?.n)
            const _areas = [...areas]
            _areas[_index] = data
            return _areas
        })
    }
    const removeArea = (key, id) => {
        setScrollTop(main.current.scrollTop)
        if(id){
            let formData = new FormData()
            formData.append('id', id)
            formData.append('token', localStorage.getItem('userToken'))
            updateData('area', 'delete', formData, (data) => {
                setAreas(areas.filter(p => p.n != key))
                toast.warning(data.updated, { theme: "colored", transition: Flip })
            })
        }else
            setAreas(areas.filter(p => p.n != key))
    }
    
    useEffect(() => {
        if(areas === null && localStorage.getItem('userToken')){
            load(true)
            let formData = new FormData()
            formData.append('token', localStorage.getItem('userToken'))
            fetch(`${apiURL}areas/`, {method: 'POST', body: formData})
                .then(res => res.json())
                .then((data) => {
                    if(typeof data.error != 'undefined')
                        toast.error(data.error, { theme: "colored", transition: Flip })
                    else
                        setAreas(data)
                })
                .catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
                .finally(() => load(false))
        }else if(areas.length == 0)
            setAreas([{n: 1}])
        if(main.current.scrollTop == 0)
            main.current.scrollTop = scrollTop
    }, [scrollTop, areas])

    return <div className="content py-0 dropdown" style={{'--dropdown-height': (240 + (areas?.length * 80))+'px'}}>
        <a className="dropdown-area" onClick={doDropdown}></a>
        <h1>Definición de áreas</h1>
        <p>Consulte y administre aquí las areas de usuarios administradores y comerciales.</p>
        {(areas?.length > 0 && <table className="list">
            <thead>
                <tr>
                    <th>Área</th>
                    <th>Correo electrónico</th>
                </tr>
            </thead>
            <tbody>
                {areas.map(a => <AddArea key={a.n} update={updateArea} remove={removeArea} area={a} />)}
            </tbody>
        </table>) || <table className="list">
            <thead>
                <tr>
                    <th><center>No hay areas que cumplan con los filtros de búsqueda.</center></th>
                </tr>
            </thead>
        </table>}
        <center>
            <a className="button" href="#" onClick={addArea}>Crear una nueva área</a>
            <p>&nbsp;</p>
        </center>
    </div>
}
export default Area