import '../styles/user.sass'
import AddCity from '../action/AddCity'
import { Link } from 'react-router-dom'
import { toast, Flip } from 'react-toastify'
import { AppContext } from '../context/AppContext'
import { useState, useEffect, useContext } from 'react'

const City = () => {
	const [ cities, setCities ] = useState(null)
	const [ scrollTop, setScrollTop ] = useState(0)
	const { main, load, updateData, apiURL } = useContext(AppContext)

	const doDropdown = e => {
		const isActive = e.target.parentElement.classList.contains('open')
		document.querySelectorAll('.dropdown').forEach((el) => el.classList.remove('open'));
		if(!isActive)
			e.target.parentElement.classList.add('open')
	}
	const addCity = () => {
		setScrollTop(main.current.scrollTop)
		setCities([...cities, {n: cities.at(-1).n + 1}])
	}
	const updateCity = (data) => {
		setScrollTop(main.current.scrollTop)
		setCities(() => {
			const _index = cities.findIndex(p => p.n == data?.n)
			const _cities = [...cities]
			_cities[_index] = data
			return _cities
		})
	}
	const removeCity = (key, id) => {
		setScrollTop(main.current.scrollTop)
		if(id){
			let formData = new FormData()
			formData.append('id', id)
			formData.append('token', localStorage.getItem('userToken'))
			updateData('city', 'delete', formData, (data) => {
				setCities(cities.filter(p => p.n != key))
				toast.warning(data.updated, { theme: "colored", transition: Flip })
			})
		}else
			setCities(cities.filter(p => p.n != key))
	}
	
	useEffect(() => {
		if(cities === null && localStorage.getItem('userToken')){
			load(true)
			let formData = new FormData()
			formData.append('token', localStorage.getItem('userToken'))
			fetch(`${apiURL}cities/`, {method: 'POST', body: formData})
				.then(res => res.json())
				.then((data) => {
					if(typeof data.error != 'undefined')
						toast.error(data.error, { theme: "colored", transition: Flip })
					else{
						console.log(data)
						setCities(data)
					}
				})
				.catch((e) => { toast.error('Ocurrió un error al intentar traer la información.', { theme: "colored", transition: Flip }) })
				.finally(() => load(false))
		}else if(cities.length == 0)
			setCities([{n: 1}])
		if(main.current.scrollTop == 0)
			main.current.scrollTop = scrollTop
	}, [scrollTop, cities])

	return <div className="content py-0 dropdown" style={{'--dropdown-height': (240 + (cities?.length * 80))+'px'}}>
		<a className="dropdown-area" onClick={doDropdown}></a>
		<h1>Ciudades de operación</h1>
		<p>Consulte y administre aquí las ciudades de operación.</p>
		{(cities?.length > 0 && <table className="list">
			<thead>
				<tr>
					<th>Ciudad</th>
				</tr>
			</thead>
			<tbody>
				{cities.map(c => <AddCity key={c.n} update={updateCity} remove={removeCity} city={c} />)}
			</tbody>
		</table>) || <table className="list">
			<thead>
				<tr>
					<th><center>No hay ciudades que cumplan con los filtros de búsqueda.</center></th>
				</tr>
			</thead>
		</table>}
		<center>
			<a className="button" href="#" onClick={addCity}>Crear una nueva ciudad</a>
			<p>&nbsp;</p>
		</center>
	</div>
}
export default City